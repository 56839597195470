<template>
  <div class="container-fluid gggg" >
    
    <div class="container muzwrapper" v-for="(item,index) in icerigi" :key="index">
      <div
        class="position-absolute p-3 text-bold lotTarih"
        style="
          
        "
      >
        <div class="row">
          <div
            class="col-6 col-xl-5 col-lg-5 d-inline-block d-xl-block d-lg-block"
            style="text-align: center; font-weight: bold !important"
          >
            {{item.baslangic}}<br />
            
          </div>

          <div class="col-1 d-none d-xl-block d-lg-block mt-auto mb-auto">
            <i
              class="fa fa-arrow-right"
              style="color: black; font-size: 24px !important"
            ></i>
          </div>

          <div
            class="col-6 col-xl-5 col-lg-5 d-inline-block d-xl-block d-lg-block"
            style="text-align: center; font-weight: bold"
          >
           {{item.bitis}}
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center text-bold pt-4 font-weight: bold !important">
            Toplam : {{item.toplamLot}} Lot
          </div>
        </div>
      </div>
 
      <div class="row mb-5 mt-5"  >
        <div class="col-12 col-xl-8 col-lg-8">
        
         <a :href="'/muzayede/'+ item.seoURL">
            <img
            
              class="card-img-top muzimg"
              :src="$store.state.siteImagePath + item.gorsel"
          /> </a>
        </div>

        <div class="col-12 col-xl-4 col-lg-4 p-2 muzyazisi" style="vertical-align: top">
           <div class="muzgit">MÜZAYEDEYE GİT</div>
           <span v-html="item.kisaAciklama"></span>

          <p>
            {{item.baslangic}}<br />
             <span v-html="item.aciklama"></span>
            
          </p>
          <p v-if="item.durum ===1">
          <span class="alert-success p-3">AKTİF</span>
          </p>
            <p v-if="item.durum ===2">
          KAPANDI
          </p>
        </div>
       
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icerigi: undefined,
    };
  },
  props: {
    icerik: Object,
    icerikId: Number,
  },
  created() {
    var th = this;

    var config = {
      url: "/api/tip9Icerik",
      headers: {
        "Content-Type": "Application/json",
      },
      method: "post",
      data: {
        Id: th.icerikId,
      },
    };
    axios(config).then((res) => {
    // console.log("MUUU",res)
      th.icerigi = res.data.data;
    });
  },
};
</script>

<style>
.lotTarih{
  background: rgba(255, 255, 255, 0.9);
          z-index: 99 !important;
          min-width: 15% !important;
          border:1px solid lightgray;
          margin-left: 30px;
}
.muzgit{
  display: none;
  position: absolute;
  bottom: 0;
  right: 4px;
  padding: 10px;
  background-color:  rgb(226, 142, 142,0.4);;
}
.muzimg {
 
  transition: 0.3s;
}
.muzwrapper{
  cursor: pointer;
padding: 10px;
}
.muzimg:hover {
  transform: scale(1.07);
}
.muzwrapper:hover{
border:2px solid rgb(226, 142, 142,0.4);
}
.muzwrapper:hover .muzimg {
  transform: scale(1.07);
}
.muzwrapper:hover .muzgit {
  display: block;
}

@media screen and (max-width: 600px) {
  .lotTarih{ 
    left:-20px;
    right: 10px;
  }
  .muzyazisi{
    text-align: center;
  }
  .muzgit{
    position: relative;
    right: 0;
  }
}
</style>
