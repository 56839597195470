import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import axios from "axios";

import VueCryptojs from 'vue-cryptojs'

Vue.use(VueCryptojs)
import { router } from "./router";

const store = new Vuex.Store({
    state: {
        siteSetting:null,
        uyeGirdi:false,
        userHash: "",
        socialModal: false,
        socialData: {},
        tostYazi: '',
        tostTitle: '',
        tostAc: false,
        tostTip: 'success',
        tostFull: '',
        tumSanatciListesi: [],
        uyeid: undefined,
        uye: null,
        loginModal: false,
        loginModalLogin: false,
        loginModalLostPass: false,
        loginModalSmsOnay: false,
        loginModalNew: false,
        sifreLinkGonderildi: false,
        sifreYenileLinkModal: false,
        sifreYenileLinkModalHash: "",
        loginTitle: "",
        sepetList: [],
        sbyloader: false,
        token: undefined,
        siteWsAddress:"",
        siteAddress:"", //'https://www.whiteclubcenter.com',
        siteImagePath:"", //'https://demo.whiteclubcenter.com/Content/images/siteImages/',
        imagePath: "",//'https://ws.whiteclubcenter.com/Content/images/siteImages/',
        siteMenus: [],
        sistemdenCikModal: false,
        ililceler: []
    },

    getters: {
        paraFormati: () => (value) => {
            let val = (value / 1).toFixed(0).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },


    },

    actions: {
        async siteSettingAl({dispatch,state},authData){
            var config = {
                method: 'post',
                url: '/api/sistemSetting',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',

                }

            };

            await axios(config)
                .then((res) => {
                   // console.log("Gelen site setting",res.data.data)
                   var CryptoJS = require("crypto-js");
                    state.siteSetting = res.data.data;
                   // console.log("State sitesetting",state.siteSetting)
                    state.siteAddress = state.siteSetting.siteAdres;
                    state.siteImagePath = state.siteSetting.siteImajPath;
                    state.siteWsAddress == state.siteSetting.siteWsAddress;

                    // console.log("SSSSSS sitee seeti",state.siteSetting)

                   /*  var sif = CryptoJS.AES.encrypt( state.siteSetting, "SBYADMIN");

                    localStorage.data99 = sif; */
                   
                   

                }).catch((error) => {




            })
        },
        async hashAl({ dispatch, state }, authData) {
            var CryptoJS = require("crypto-js");
            //localStorage.clear(); 
            //state.uye = null;

            if (localStorage.data4) {


                //var CryptoJS = require("crypto-js");

                let uyeHashi = CryptoJS.AES.decrypt(localStorage.data4, "SBYADMIN").toString(CryptoJS.enc.Utf8)

               // console.log("KAYITLI HASH",uyeHashi)

                state.userHash = uyeHashi;
            } else {

                var config = {
                    method: 'post',
                    url: '/api/hashAl',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',

                    }

                };

                await axios(config)
                    .then((res) => {
                   // console.log("GELEN YENİ HASH",res)
                        state.userHash = res.data.data;
                        var sif = CryptoJS.AES.encrypt(state.userHash, "SBYADMIN");

                        localStorage.data4 = sif;

                    }).catch((error) => {




                    })
            }
        },
       async sipayTokenAl({ dispatch, state }, authData) {
        
            var conf = {
                url: "/api/sipayToken",
                method: "post",
                headers: {
                    'Content-Type': 'Application/json'
                }
            }

           await axios(conf).then((res) => {
            var CryptoJS = require("crypto-js");
              // console.log("Sipay ",  JSON.parse(res.data.data).data.token);
                 localStorage.data9 = CryptoJS.AES.encrypt(JSON.parse(res.data.data).data.token, "SBYADMIN");
            })

        },
        async uyeBilgileriAl({dispatch,state}) {
             
             var CryptoJS = require("crypto-js");
            /*  alert( CryptoJS.AES.decrypt(localStorage.data, "SBYADMIN").toString(
                CryptoJS.enc.Utf8
              )); */

            if(localStorage.data){
                
                var config = {
                  url: "/api/users/uyeBilgi",
                  method: "post",
                  headers: {
                    "Content-Type": "Application/json",
                  },
                  data: {
                    uyeId: CryptoJS.AES.decrypt(localStorage.data, "SBYADMIN").toString(
                  CryptoJS.enc.Utf8
                ),
                    muzayedeId: 0,
                  },
                };
      
               await  axios(config).then((res) => {
                // console.log("üye bilgi", res.data);
                  if (res.data.success) {
                     state.uye = res.data.data
                       
                  }
                 // th.adreslerSepet()
                  //state.sepetList = res.data.data;
                });
            }
           
              
             
          },
        loginModalAc({ dispatch, state }, authData) {
            state.loginTitle = "Üye Girişi";

            state.loginModalLostPass = false;
            state.loginModalNew = false;
            state.loginModalSmsOnay = false;
            state.loginModal = true;
            state.loginModalLogin = true;
            //this.$bvModal.show("lotModalAc");
        },
        yeniUyeOl({ dispatch, state }, authData) {
            state.loginTitle = "Üye Ol";

            state.loginModalLostPass = false;
            state.loginModalNew = true;
            state.loginModalSmsOnay = false;
            state.loginModalLogin = false;
            state.loginModal = true;
        },
        socialShareOpen({ dispatch, state }, authData) {
            state.socialModal = true;
            state.socialData = { link: authData.link, data: authData.data };
        },
       
        uyeligiTamamla({ dispatch, state }, authData) {

            dispatch("makeTost", {
                title: authData.success ? "Hoşgeldiniz." + authData.adSoyad : authData.message,
                tip: authData.success ? "success" : "danger",
                yazi: authData.message,
                full: true

            })


            dispatch("uyeState").then(() => {
                setTimeout(() => {
                    state.loginModal = false;
                }, 1000);
            });

        },
        async ililceler({ dispatch, state }, authData) {

            var conf = {
                url: "/api/ililce",
                method: "post",
                headers: {
                    'Content-Type': 'Application/json'
                }
            }

            await axios(conf).then((res) => {
                state.ililceler = res.data.data;
            })

        },
        sistemdenCikOnay({ dispatch, state }, authData) {

              
            localStorage.removeItem('data');
            localStorage.removeItem('data1');
            localStorage.removeItem('data2');
            localStorage.removeItem('ad');
            localStorage.removeItem('sepetAdres')
            state.uye = null;
            dispatch("uyeState").then(() => {
                state.sistemdenCikModal = false;
               
                router.push({ path: '/' })
                //dispatch("sepetAl");
            });
        },
        sistemdenCik({ dispatch, state }, authData) {
            state.sistemdenCikModal = true;
        },
        makeTost({ dispatch, state }, authData) {

            authData.tip = 'b-toaster-top-center'
            state.tostYazi = authData.yazi;
            state.tostTitle = authData.title;
            state.tostTip = authData.tip;
            state.tostFull = authData.full ? 'b-toaster-top-full' : 'b-toaster-top-right';
            state.tostAc = true;


        },
        async sepeteAt({ dispatch, state }, authData) {



            dispatch("hashAl");

            /*  await dispatch("uyeState").then(() => { */

            /*   if (state.uyeid) {*/
            var CryptoJS = require("crypto-js");
            let uyeId = 0;
            if (localStorage.data) {
                uyeId = CryptoJS.AES.decrypt(localStorage.data, "SBYADMIN").toString(CryptoJS.enc.Utf8);
            }
            var config = {
                url: "/api/sepet/sepeteAt",
                data: {
                    uyelerId: uyeId,
                    eserId: authData.item.Id,
                    sepetHash: state.userHash

                },
                headers: {
                    'Content-Type': 'Application/json'
                },
                method: "post"
            }

            axios(config).then((res) => {
                //console.log("OOO", res)
                if (res.data.success) {
                    dispatch("makeTost", {
                        title: "UYARI",
                        tip: "success",
                        yazi: authData.item.kisaAciklama + " Sepetinize eklendi.",
                        full: true

                    })
                    dispatch("sepetAl")
                } else {
                    dispatch("makeTost", {
                        title: "UYARI",
                        tip: "warning",
                        yazi: res.data.message + "<br>" + authData.item.kisaAciklama + " Sepetinize eklenemedi.",
                        full: true

                    })
                }
            })
            /*  } else {
                 this.dispatch("makeTost", {
                     title: "UYARI",
                     tip: "danger",
                     yazi: "Üye Girişi yapınız",
                     full: true

                 })
             } */
            /*  }) */



        },
        async tumSanatcilar({ dispatch, state }) {

            var config = {
                url: "/api/sanatci/sanatcilar",

                method: "post",
                headers: {
                    'Content-Type': 'Application/json'
                }
            }

            await axios(config).then((res) => {

                state.tumSanatciListesi = res.data.data;
            })
        },
        async sanatciBilgiAl({ dispatch, state }, authData) {

            var config = {
                url: "/api/sanatci/sanatciBilgi",
                data: {
                    hashLink: authData.hashLink
                },
                method: "post",
                headers: {
                    'Content-Type': 'Application/json'
                }
            }

            return await axios(config).then((res) => {

                return res.data;
            })

        },
        async sepetAdetDegistir({ dispatch, state }, authData) {


            var config = {
                url: "/api/sepet/sepetAdetDegistir",
                method: "post",
                headers: {
                    'Content-Type': 'Application/json'
                },
                data: {
                    Id: authData.sepetId,
                    adeti: authData.adeti
                }

            }

            await axios(config).then((res) => {
                dispatch("sepetAl")
                // console.log("ALLLL",res)
                //state.sepetList = res.data.data;

            })
        },
        async sepetAl({ dispatch, state }) {

            /*  let sendUyeid = 0;
 
 
 
             await dispatch("uyeState").then(() => {
 
                 if (state.uyeid) {
                     var CryptoJS = require("crypto-js");
                     sendUyeid = CryptoJS.AES.decrypt(localStorage.data, "SBYADMIN").toString(CryptoJS.enc.Utf8)
                 }
             }) */
            await dispatch("hashAl").then(() => {
                var config = {
                    url: "/api/sepet/sepetListe",
                    method: "post",
                    headers: {
                        'Content-Type': 'Application/json'
                    },
                    data: {
                        sepetHash: state.userHash
                    }

                }

                axios(config).then((res) => {
                    //console.log("ALLLL",res)
                    state.sepetList = res.data.data;
                })
            })

        },
        async sipayKeys({dispatch,state}){

            var config = {
                url: "/api/sipayKeys",
                headers: {
                    'Content-Type': 'Application/json'
                },
                 
                method: "post"
            }

            return await axios(config).then((res) => {
                // console.log(res.data)
                if (res.data.success) {
                    return  { success:res.data.success , data: res.data.data};
                }
                
            })
        },
        async uyeState({ dispatch, state }) {
            // console.log("UYE STATe",localStorage.data)
            if (localStorage.data) {


                var CryptoJS = require("crypto-js");
                let uyeData = CryptoJS.AES.decrypt(localStorage.data, "SBYADMIN").toString(CryptoJS.enc.Utf8)
                let uyeData1 = CryptoJS.AES.decrypt(localStorage.data1, "SBYADMIN").toString(CryptoJS.enc.Utf8)
                let uyeData2 = CryptoJS.AES.decrypt(localStorage.data2, "SBYADMIN").toString(CryptoJS.enc.Utf8)

                let f = false;
                await dispatch("uyeStateCheckDb", { loginName: uyeData1, loginPass: uyeData2 }).then((res) => {


                    f = res;
                })
                state.uyeid = (f == true ? true : undefined);


            } else {

                state.uyeid = undefined
            }
        },

        async uyeStateCheckDb({ dispatch, state }, authData) {

            var config = {
                url: "/api/users/loginKontrol",
                headers: {
                    'Content-Type': 'Application/json'
                },
                data: {
                    eposta: authData.loginName,
                    sifre: authData.loginPass
                },
                method: "post"
            }

            return await axios(config).then((res) => {
                //console.log("UYEEE", res.data)
                if (res.data.success) {

                }
                return res.data.success;
            })
        },

        async tokenAl({ commit, dispatch, state }, authData) {


            var qs = require('qs');
            var data = qs.stringify({
                'grant_type': 'password',
                'username': 'sntmztadmin',
                'password': '1Q2w3e4r.-@--'
            });

            var config = {
                method: 'post',
                url: '/token',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',

                },
                data: data
            };

            await axios(config)
                .then((res) => {
                    //console.log("TOKeN",res.data.access_token)
                    state.token = res.data.access_token;


                }).catch((error) => {




                })
        },
        async topMenu({ dispatch, state }) {

            var config = {
                url: "/api/menuler",
                headers: {
                    'Content-Type': 'Application/json'
                },
                method: "post",

            }
            await axios(config).then((res) => {
                //console.log("Gelen Menuler", res)
                state.siteMenus = res.data.data;
                localStorage.siteMenus = JSON.stringify(state.siteMenus);
            })
        },

        async sayfaIcerikListesi({ dispatch, state }, authData) {


            
           

            

            var config = {
                url: "/api/sayfaIcerikler",
                headers: {
                    'Content-Type': 'Application/json'
                },
                method: "post",
                data: {
                    Id: authData.Id
                }

            }
            return await axios(config).then((res) => {
                // console.log("iceerikleri", res.data.data)
                return res.data.data;
            })

        },
        async icerikBilgi({ dispatch, state }, authData) {

            var config = {
                url: "/api/icerikBilgi",
                headers: {
                    'Content-Type': 'Application/json'
                },
                method: "post",
                data: {
                    Id: authData.Id
                }

            }
            return axios(config).then((res) => {

                return res.data.data;
            })
        },
        async tumSayfalarIcerikListesi({ dispatch, state }, authData) {
            // alert(authData.seoUrl)
            let pageId = 1;


            var config = {
                url: "/api/tumSayfalaraIcerikler",
                headers: {
                    'Content-Type': 'Application/json'
                },
                method: "post"

            }
            return await axios(config).then((res) => {

                return res.data.data;
            })

        },

    },
})

export default store