import Vue from 'vue';
import VueRouter from 'vue-router';

 
Vue.use(VueRouter);


const muzayedeShow = resolve => {
  require.ensure(["./components/templates/muzayedeShow.vue"], () => {
    resolve(require("./components/templates/muzayedeShow.vue"))
  })
}

 
const blogOku = resolve => {
  require.ensure(["./components/templates/blogOku.vue"], () => {
    resolve(require("./components/templates/blogOku.vue"))
  })
}
const pgnotfound = resolve => {
  require.ensure(["./components/templates/pageNotFound.vue"], () => {
    resolve(require("./components/templates/pageNotFound.vue"))
  })
}
const sepet = resolve => {
  require.ensure(["./components/templates/sepet.vue"], () => {
    resolve(require("./components/templates/sepet.vue"))
  })
}
const sanatci = resolve => {
  require.ensure(["./components/templates/sanatci.vue"], () => {
    resolve(require("./components/templates/sanatci.vue"))
  })
}

const eserShow = resolve => {
  require.ensure(["./components/templates/eserShow.vue"],()=>{
    resolve(require("./components/templates/eserShow.vue"))
  })
}
const eserDetay = resolve => {
  require.ensure(["./components/templates/eserDetay.vue"],()=>{
    resolve(require("./components/templates/eserDetay.vue"))
  })
}
const uyePanel = resolve => {
  require.ensure(["./components/templates/uyePanel.vue"],()=>{
    resolve(require("./components/templates/uyePanel.vue"))
  })
}
const sipayVue = resolve => {
  require.ensure(["./components/templates/sipayVue.vue"],()=>{
    resolve(require("./components/templates/sipayVue.vue"))
  })
}
const Appi = resolve => {
  require.ensure(["./App.vue"],()=>{
    resolve(require("./App.vue"))
  })
}
export const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  base: "/",
  mode: 'history',
  routes: [
    { path: '/', component: Appi, name: "" },
    { path: '/:filter', component: Appi, name: "" },
    { path: '/sepet/sepetDurum', component: sepet, name: "sepet-durum" },
    { path: '/blog/:blog', component: blogOku, name: "blog-oku" },
    {path:'/muzayede/:muzayede',component:muzayedeShow,name:"muzayede"},
    {path:'/notFound/sayfa-bulunamadi',component:pgnotfound,name:"sayfa-bulunamadi"},
    {path:'/sanatcilar/:sanatci',component:sanatci,name:"sanatci"},
    {path:'/eser/:eser',component:eserShow,name:"eserBilgi"},
    {path:'/muzayede/eserdetay/:lotid/:lotno',component:eserDetay,name:"eserDetay"},
    {path:'/uye/panel/:konum',component:uyePanel,name:"uyePanel"},
    {path:'/sifreYenileLink/:guid',component:Appi},
    {path:'/sipay/:result',component:sipayVue},
    {
      path: '*', component: Appi,
      beforeEnter(to, from, next) {
        
       
  
      }, 
      beforeRouteUpdate(to, from, next) {
        
       /*   console.log(from)
        console.log(to)   */
      },

    }
  ]
});
/* router.beforeEach((to, from, next) => {
  if (to.name === from.name) return
  else
    next()
}) */
router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
 // console.log("to",to,"from",from,"next",next)
  if (to.name) {
    
    //store.state.sbyloader=true;
    // Start the route progress bar.
   // NProgress.start()
  }
  next()
})


router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
/*  setTimeout(() => {
     store.state.sbyloader=false;
 }, 3000); */

 // NProgress.done()
})
