<template>
  <div>
     
    <span v-if="icerigi != null">
      <tip1
        v-if="icerigi.icerikTiplerId === 1"
        :itemi="icerigi"
        :icerikId="icerikId"
      ></tip1>
      <tip2
        v-if="icerigi.icerikTiplerId === 2"
        :itemi="icerigi"
        :icerikId="icerikId"
      ></tip2>
 <tip3
        v-if="icerigi.icerikTiplerId === 3"
        :itemi="icerigi"
        :icerikId="icerikId"
      ></tip3>

      <tip4
        v-if="icerigi.icerikTiplerId === 4"
        :itemi="icerigi"
        :icerikId="icerikId"
      ></tip4>
       <tip7
        v-if="icerigi.icerikTiplerId === 7"
        :itemi="icerigi"
        :icerikId="icerikId"
      ></tip7>
      <tip8
        v-if="icerigi.icerikTiplerId === 8"
        :itemi="icerigi"
        :icerikId="icerikId"
      ></tip8>
      <tip9
        v-if="icerigi.icerikTiplerId === 9"
        :itemi="icerigi"
        :icerikId="icerikId"
      ></tip9>
      <tip10
        v-if="icerigi.icerikTiplerId === 10"
        :itemi="icerigi"
        :icerikId="icerikId"
      ></tip10>
      <tip11
        v-if="icerigi.icerikTiplerId === 11"
        :itemi="icerigi"
        :icerikId="icerikId"
      ></tip11>
    </span>
  </div>
</template>
<script>
import tip1 from "./templates/icerikTip1";
import tip2 from "./templates/icerikTip2";
import tip3 from "./templates/icerikTip3";
import tip4 from "./templates/icerikTip4";
import tip7 from "./templates/icerikTip7";
import tip8 from "./templates/icerikTip8";
import tip9 from "./templates/icerikTip9";
import tip10 from "./templates/icerikTip10";
import tip11 from "./templates/icerikTip11";

export default {
  components: {
    tip1,
    tip2,
tip3,
tip4,
    tip7,
    tip8,
    tip9,
    tip10,
    tip11
  },
  data() {
    return {
      icerigi: null,
    };
  },
  props: {
    icerikId: Number,
  },
  mounted() {
    this.icerigi = [];
    //  alert(this.$route.params.filter)
    this.$store.dispatch("icerikBilgi", { Id: this.icerikId }).then((res) => {
      this.icerigi = res;
    });
  },
};
</script>
<style></style>
