<template>
  <div>
     <b-container fluid v-if="icerigi != null">
      <b-row>
        <b-col
          v-for="(item, index) in icerigi"
          :key="index"
          class="col-12 col-xl-3 col-lg-3 p-3"
        >
          <div class="card" >
            <div class="card-header">

             
             <span
                class="font1 text-muted text-center"
                style="font-size: 13px"
                v-html="item.yazisi"
              ></span>
            </div>
            <div class="card-body text-center p-0 m-0">
             <img  @click="icerikGoster(item.link)"    
                  style="cursor:pointer;"
                   
                    class="w-100 card-img-top"
                    :src="$store.state.siteImagePath + item.resim"
                     
                  />
            </div>
          
          
          </div>
        </b-col>
      </b-row>
    
    </b-container>
   
  </div>
</template>

<script>
export default {
  data() {
    return {
       
      icerigi: null,
      slide: 0,
      perList: 4,
    };
  },

 methods:{
      icerikGoster(link){
          this.$router.push({path: link})
      }
  },
  components: {},
  props: {
    itemi: {},
    icerikId: Number,
  },
  mounted() {
    var th = this;
    //tip8icerikle tip2icerik datası ayynı
    var config = {
      url: "/api/tip2Icerik",
      headers: {
        "Content-Type": "Application/json",
      },
      method: "post",
      data: {
        Id: th.icerikId,
      },
    };
    axios(config).then((res) => {
        
      th.icerigi = res.data.data;
    });
  },
};
</script>

<style></style>
