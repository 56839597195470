<template>
 
  <b-navbar toggleable="lg" type="light" variant="light" sticky>
    <b-sidebar
      id="sidebar-no-header"
      v-model="hesabMenuHide"
      aria-labelledby="sidebar-no-header-title"
      no-header
      shadow
      v-if="hesabMenuHide && $store.state.uyeid != undefined"
    >
      <template>
        <div class="p-3">
          <h4 id="sidebar-no-header-title">
            <b-link variant="link" block @click="hesabimhide()"
              ><b-icon-arrow-left></b-icon-arrow-left>&nbsp;</b-link
            >Hesabım
          </h4>

          <nav class="mb-3">
            <b-nav vertical>
              <b-nav-item href="/uye/panel/bilgilerim">Bilgilerim</b-nav-item>
              <b-nav-item href="/uye/panel/tekliflerim">Müzayede Tekliflerim</b-nav-item>
              <b-nav-item href="/uye/panel/takipettiklerim"
                >Takip Ettiğim Eserler</b-nav-item
              >
              <b-nav-item href="/uye/panel/sepetim">Sepetim</b-nav-item>
              <b-nav-item href="/uye/panel/siparislerim">Siparişlerim</b-nav-item>
              <b-nav-item @click="$store.dispatch('sistemdenCik')"
                >Sistemden Çık</b-nav-item
              >
            </b-nav>
          </nav>
        </div>
      </template>
    </b-sidebar>
    <b-navbar-brand v-if="$store.state.siteSetting!=null"  href="/">
        <img
        :src=" $store.state.siteImagePath + $store.state.siteSetting.logo"
       @click="toHome"
        :alt="$store.state.siteSetting.siteAddress"
        style="width: auto; height: 50px"
      />  
      <!-- <span class="logoFontu"> White Club Center</span> -->
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto mr-auto">
        
        <span v-for="(item, index) in $store.state.siteMenus.filter((k)=>k.ustId==0)" :key="index" class="p-2">
          <span
            v-if="$store.state.siteMenus.filter((o) => o.ustId == item.Id).length > 0"
          >
            <b-nav-item-dropdown :text="item.ad">
              <b-dropdown-item
                v-for="(item2, index2) in $store.state.siteMenus.filter(
                  (o) => o.ustId == item.Id
                )"
                :key="index2"
                :to="{ path: '/' + item2.seoUrl }"
              >
                {{ item2.ad }}
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </span>
          <span v-else>
            <b-nav-item v-if="item.ustId == 0" :href="'/' + item.seoUrl">
              {{ item.ad }}
            </b-nav-item>
          </span>
        </span>
        <span class="p-2 mobileAc"   v-if="$store.state.uyeid != undefined">
          <b-link @click="hesabimhide()"> Hesabım </b-link>
        </span>
        <span class="p-2 mobileAc"   v-if="$store.state.uyeid != undefined">
          <b-link @click="$store.dispatch('sistemdenCik')">Sistemden Çık</b-link>
        </span>
         
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <form class="form-inline my-2 my-lg-0 mobileGizle">
        <b-button variant="link" right>
        
          <b-icon icon="search"></b-icon>
        </b-button>
        <b-button v-if="$store.state.siteSetting!=null ? $store.state.siteSetting.sepetAktif:false" variant="link" right :to="{ path: '/sepet/sepetDurum' }">
          <b-icon icon="basket"></b-icon
          ><b-badge variant="warning">{{ $store.state.sepetList.length }}</b-badge>
        </b-button>
        <b-dropdown
          id="dropdown-1"
          variant="link"
          right
          class="m-md-2"
          v-if="$store.state.uyeid != undefined"
        >
          <template #button-content>
            <b-icon icon="person-fill"></b-icon
            ><span style="font-size: 13px">{{ $store.state.uye!=null ? $store.state.uye.adSoyad :"" }}</span>
          </template>
          <b-dropdown-item to="/uye/panel/bilgilerim">Bilgilerim</b-dropdown-item>
          <b-dropdown-item to="/uye/panel/tekliflerim"
            >Müzayede Tekliflerim</b-dropdown-item
          >
          <b-dropdown-item to="/uye/panel/takipettiklerim"
            >Takip Ettiğim Eserler</b-dropdown-item
          >

          <b-dropdown-item to="/uye/panel/sepetim">Sepetim</b-dropdown-item>
          <b-dropdown-item to="/uye/panel/siparislerim">Siparişlerim</b-dropdown-item>
          <b-dropdown-item @click="$store.dispatch('sistemdenCik')"
            >Sistemden Çık</b-dropdown-item
          >
        </b-dropdown>

        <b-button
          variant="link"
          right
          v-if=" $store.state.uyeid == undefined &&  ($store.state.siteSetting!= null ? $store.state.siteSetting.uyelikAktif == true :false)"
          @click="$store.dispatch('loginModalAc')"
          class="sbySiyazBeeyazButton2 m-1"
          >Giriş Yap</b-button
        >

        <b-button
          v-if="$store.state.uyeid == undefined && ($store.state.siteSetting != null ? $store.state.siteSetting.uyelikAktif == true :false)"
          right
          variant="link"
          @click="$store.dispatch('yeniUyeOl')"
          class="sbySiyazBeeyazButton3 m-1"
          >Üye Ol</b-button
        >
      </form>
    </b-collapse>
  </b-navbar>
</template>
<script>
export default {
  data() {
    return {
      hesabMenuHide: false,
    };
  },
  created() {
   /*  this.$store.dispatch("topMenu");
    this.$store.dispatch("uyeAdi"); */
    //console.log(this.$store.state.uye)
  },

  methods: {
    toHome(){
      this.$router.go("/");

    },  
    hesabimhide() {
      this.hesabMenuHide = !this.hesabMenuHide;
    },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Lao+Looped:wght@500&display=swap');
.logoFontu{
  font-family: 'Noto Sans Lao Looped', sans-serif;
}
.sbySiyazBeeyazButton2 {
  padding-right: 4px !important;
  padding-left: 4px !important;
  background: white !important;
  border: 1px solid black !important;
  text-align: center !important;
  box-sizing: border-box !important;

  text-decoration: none !important;
  color: black !important;
  cursor: pointer !important;
  border-radius: 6px;
  font-size: 14px;
}
.sbySiyazBeeyazButton3 {
  padding-right: 4px !important;
  padding-left: 4px !important;
  background: black !important;
  border: 1px solid black !important;
  text-align: center !important;
  box-sizing: border-box !important;

  text-decoration: none !important;
  color: white !important;
  cursor: pointer !important;
  border-radius: 6px;
  font-size: 14px;
}
.siteMenu nav {
  background-color: transparent !important;
}
.siteMenu .navbar-light,
.navbar-nav,
.nav-link {
  color: black !important;
}
.siteMenu .dropdown-menu {
  border: 0 !important;
}

.siteMenu .dropdown-item:hover,
.dropdown-item:focus {
  border: 0 !important;
  background-color: transparent !important;
}
.siteMenu .dropdown-item:hover {
  border-bottom: 1px solid #e3e3e3 !important;
}
.siteMenu li a {
  font-family: "Montserrat", sans-serif !important;

  color: black !important;
}
.siteMenu {
  background: transparent !important;
  color: black !important;
}
.siteMenu ul {
  border: 0 !important;
  background-color: none !important;
}

.nav-link {
  font-family: "Montserrat", sans-serif !important;

  color: black !important;
}

.siteMenu a:hover {
  border-bottom: 1px solid darkgray;
}
</style>
