import Vue from 'vue'
import store from './store'
import {router} from './router'
import axios from "axios"
window.axios = require('axios');
axios.defaults.baseURL = "https://ws.whitesportscenter.com"
//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

axios.interceptors.request.use(function (config) {

  if(config.url != "/token"){
    
    config.headers.Authorization = "Bearer " + store.state.token;
    //config.headers.Accept = "*/*";

  }
 
  /* if(config.url.indexOf("sepeteAt")>-1){
    console.log("kkkkkkk",config)
  }
  if(config.url.indexOf("kuponKontrol")>-1){
    console.log("kuponKontrol",config)
  } */
   //console.log("kkkkkkk",config)
  return config;
});

 



/* 
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

Vue.use(Buefy) */


import { BootstrapVue, BootstrapVueIcons, ModalPlugin,IconsPlugin,BCarousel ,NavbarPlugin,CardPlugin, ToastPlugin, BToast,SkeletonPlugin} from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(ModalPlugin)

// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(NavbarPlugin)
Vue.use(CardPlugin)
Vue.use(ToastPlugin)
Vue.use(SkeletonPlugin)
Vue.component('b-toast', BToast)
 Vue.component('b-carousel', BCarousel)  

 
/* import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel); */
import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter,[
  
  { // default name 'currency_2'
    name: 'tlformat',
    symbol: ' ₺',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'back',
    symbolSpacing: false,
    avoidEmptyDecimals: '',
  } 
 ])
 Vue.use(VueCurrencyFilter,[
  
  { // default name 'currency_2'
    name: 'virgulFormat',
    symbol: '',
    thousandsSeparator: '',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'back',
    symbolSpacing: false,
    avoidEmptyDecimals: '',
  } 
 ])
  import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

Vue.component('VueSlider', VueSlider) 

import '../src/assets/ortak.css'
import VueCountdownTimer from 'vuejs-countdown-timer';
Vue.use(VueCountdownTimer);
 
Vue.use(require('vue-moment'));
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

 
import VueCryptojs from 'vue-cryptojs'
Vue.use(VueCryptojs)

import Multiselect from 'vue-multiselect'

// register globally
Vue.component('multiselect', Multiselect)
import 'vue-multiselect/dist/vue-multiselect.min.css'

/* import { SFacebook, STwitter, SWhatsApp, SEmail } from "vue-socials"; */


import App from './App.vue'
/* import { SEmail, STwitter, SWhatsApp } from 'vue-socials';
 */
import VueSocials from '../node_modules/vue-socials';

Vue.use(VueSocials)
import VueMeta from 'vue-meta'

Vue.use(VueMeta)
 
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
