<template>
    <div :class="itemi.ozelClass" :style="itemi.ozelCss">
         
        <div v-html="itemi.baslik">
 
</div>

          <b-card-group>
            <lot-bilgi 
              v-for="(lotu, index) in lots"
              :key="index"
              :lotTumBilgi="lotu"
              :sira="index"
              :distan="false"
              
              :kapandiysaGizle="lotu.kapandi"
            >
            </lot-bilgi>
          </b-card-group>  
         <div class="container-fluid text-center justify-content-center mt-3 mb-3" v-if="lots.length>0">
            <div class="row">
                <div class="col-12">
                   
                    <b-button
                class="teklifBut p-2"
                :to="'/muzayede/' +   lots[0].muzaydeseo"
                
                
                variant="secondary"
                > TÜM ESERLER </b-button> <hr/>
                </div>
            </div>
         </div>
    </div>
   
</template>
  
<script>
import lotBilgi from "./lotu";
 
var CryptoJS = require("crypto-js");
 
export default {
    data() {
        return {
            lots: [],
        };
    },
    components: {
    lotBilgi,
    
  },
    props: {
        itemi: {},
        icerik: Object,
        icerikId: Number,
    },
    methods: {
        lotListesi() {
            var th = this;

            //console.log("CARİİé", localStorage.data)
            let sendUyeid = 0;
            if (localStorage.data) {
                sendUyeid = CryptoJS.AES.decrypt(localStorage.data, "SBYADMIN").toString(
                    CryptoJS.enc.Utf8
                );
            }
            var config = {
                method: "post",
                url: "/api/lots/icerikTip11Lotlar",
                headers: {
                    "Content-Type": "Application/json",
                },
                data: {
                    icerikId: th.icerikId,
                    cariId: sendUyeid,


                },
            };
           // console.log("Config",config)
            axios(config)
                .then(function (response) {
              // console.log("DDDD",response.data)

                   

                    th.lots = response.data.data;
                 
                    
 
                })
                .catch(function (error) { });
        },
    },
    created() {
        var th = this;
       // console.log("içerik 11",th.icerikId)
        th.lotListesi();
    },
};
</script>
  
<style>

</style>
  