<template>
  <div>
 
    <div v-for="(item, index) in icerikListesi" :key="index">
    
        <icerik :icerikId="item.Id"></icerik> 
    </div>
  </div>
</template>
<script>
import icerik from "./icerik";
export default {
  components: {
    icerik,
  },
  data() {
    return {
      
      icerikListesi: [],
    };
  },

  created() {
    this.icerikListesi = [];
    
      
    this.$store.dispatch("tumSayfalarIcerikListesi").then((res) => {
       
      this.icerikListesi = res;
    });
  },
};
</script>
<style></style>
