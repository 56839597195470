 
<template>
<div :class="itemi.ozelClass" :style="itemi.ozelCss"> 

<div v-html="itemi.icerigi">
 
   </div>
 
</div>
</template>

<script>
export default {
    props:{
        itemi: {},
        icerikId: Number,
    },
    
    mounted(){
       // this.itemi.icerigi = this.itemi.icerigi.replace(/<img alt="" src="/g,'<img src="' + this.$store.state.siteImagePath +'"')
    }
}
</script>

<style>

</style>
