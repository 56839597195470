<template>
    <div :class="itemi.ozelClass" :style="itemi.ozelCss">

        <div v-if="icerigi != []">
            <div v-if="itemi.kolonSayisi == null">
                <div ref="swiper" class="swiper">
                   
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(res, resindex) in icerigi" :key="resindex">
                            <a :href="res.link == null ? '#' : res.link">
                                <img :src="$store.state.siteImagePath + res.resim" />
                            </a>
                        </div>
                    
</div>
                    <div v-if="navButGoster" class="swiper-button-prev"></div>
                    <div v-if="navButGoster" class="swiper-button-next"></div>

                    <div v-if="navButGoster" class="swiper-pagination"></div>
                </div>
                

            </div>
            <div v-else class="mt-2 mb-2">
                <div v-html="itemi.baslik">

                </div>
                <b-alert show variant="danger" v-if="yeniPeyGeldi">Yeni Pey Geldi
                    <br />
                    {{ yeniPeyBilgi.lotBilgi.LotNo }}
                    <br />
                    {{ yeniPeyBilgi.lotBilgi.sanatciAd }}
                </b-alert>
                <div ref="swiper" class="swiper">
                   
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(itt, resindex) in eserRes" :key="resindex">
                            <a class="card w-100" :href="'/muzayede/eserdetay/' + itt.lotId + '/' + itt.lotBilgi.LotNo">
                                <div class="embed-responsive embed-responsive-4by3">
                                    <img alt="Card image cap" class="card-img-top embed-responsive-item"
                                        :src="$store.state.siteImagePath + itt.lotBilgi.eserResimler[0].resim" />
                                </div>
                                <div class="card-block p-2">
                                    <h5 class="card-title">{{ itt.lotBilgi.sanatciAd }}</h5>
                                    <p class="card-text">
                                        {{ itt.lotBilgi.LotNo }}
                                    </p>

                                    <h6>
                                        {{ $store.getters.paraFormati(itt.lotBilgi.sonFiyat) }} ₺
                                    </h6>



                                </div>
                            </a>
                        </div>
                    </div>
               
                    <div v-if="navButGoster" class="swiper-button-prev"></div>
                    <div v-if="navButGoster" class="swiper-button-next"></div>


                </div>
 




            </div>
        </div>

    </div>
</template>


<script>


import { Icon } from '@iconify/vue2';
/* import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper"; */
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
export default {
    setup() {
        return {
            modules: [Autoplay, Pagination, Navigation],
        };
    },
    data() {
        return {
            swp: null,
            navButGoster: true,
            cokluSliderKim: null,
            yeniPeyGeldi: false,
            yeniPeyBilgi: {},
            poolData: null,
            navvar: true,
            eserRes: [],
            icerigi: [],
            stt: {
                "dots": true,
                "dotsClass": "slick-dots custom-dot-class",
                "edgeFriction": 0.35,
                "infinite": false,
                "speed": 500,
                "slidesToShow": 1,
                "slidesToScroll": 1
            }

        };
    },

    methods: {
        slidela() {
            var th = this;

           
                
              
            if (th.icerigi.length == 1) {
                th.navButGoster = false;
            }
            if (th.itemi.kolonSayisi == null) {

                th.swp.params.slidesPerView = 1

            } else {
                //console.log("bura")
                th.swp.params.centeredSlides = true;
                th.swp.params.slidesPerView = th.itemi.kolonSayisi;
               
                th.swp.params.breakpoints = {

                    320: {
                        slidesPerView: 1,

                    },

                    480: {
                        slidesPerView: 3,

                    },

                    640: {
                        slidesPerView: 6,

                    }
                }




            }
        },
        onSwiperA(swiper) {
            this.cokluSliderKim = swiper;
        },
        onInitCarousel() {
            // console.log('our carousel is ready')
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        async peyleriYenile() {
            var th = this;

            var config = {
                url: "/api/tip2Icerik",
                headers: {
                    "Content-Type": "Application/json",
                },
                method: "post",
                data: {
                    Id: th.icerikId,
                },
            };
            await axios(config).then((res) => {

                th.icerigi = res.data.data;

                //console.log("iççç",th.icerigi.length)
                if (th.icerigi.length <= 1) {
                    th.navvar = false;
                }
                var i = 0;
                for (i = 0; i < th.icerigi.length; i++) {

                    if (th.eserRes[i].peyId != th.icerigi[i].peyId) {
                        // console.log("pey değişti ", "eski", th.eserRes[i] , "yeni", th.icerigi[i])
                        if (!th.yeniPeyGeldi) {
                            th.yeniPeyGeldi = true;
                            th.yeniPeyBilgi = th.icerigi[i];

                            th.cokluSliderKim.slideTo(0);
                            setTimeout(() => {
                                th.yeniPeyGeldi = false;
                            }, 2000);
                        }


                        th.eserRes[i] = th.icerigi[i]
                    }
                }


            });
        }
    },
    components: {
        Swiper,

        Icon
    },
    props: {
        itemi: {},
        icerikId: Number,
    },
    created() {

        var th = this;

        var config = {
            url: "/api/tip2Icerik",
            headers: {
                "Content-Type": "Application/json",
            },
            method: "post",
            data: {
                Id: th.icerikId,
            },
        };
        axios(config).then((res) => {

            th.icerigi = res.data.data;

            // console.log("iççç",th.icerigi.length)
            if (th.icerigi.length <= 1) {
                th.navvar = false;
            }

            th.icerigi.forEach(element => {
                // console.log("elemenet", element)


                th.eserRes.push({
                    lotId: element.lotId,
                    peyId: element.peyId,
                    lotBilgi: element.lotBilgi,


                })

            });
            th.slidela();
        });



        if (th.itemi.muzayedeIcinOtoSlidermi) {
            if (th.itemi.muzayedeIcinOtoSliderSadecePeyler) {
                th.poolData = setInterval(() => {
                    th.peyleriYenile();

                }, 8000);
            }
        }
    },
    mounted() {

        this.swp = new Swiper(this.$refs.swiper, {

            modules: [Navigation, Pagination, Autoplay],
            loop: true,
            direction : 'horizontal',
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            speed: 2000,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }

        })

    }
};
</script>
<style>
/* .swiper {
    width: 100%;
    height: 600px;
} */
.swiper-container {
  width: 100%;
  height: 600px;
/*     background: #fd5; */
}
/* .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

      
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
} */

.swiper-slide img {
    width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next,
.swiper-button-prev {
    color: white !important;
}

.swiper-pagination-bullet {
    color: white !important;
    background-color: rgb(255, 255, 255, 1) !important;
}

.swiper-pagination-bullet-active {
    color: white !important;
    background-color: white !important;
}
</style>