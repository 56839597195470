<template>
  <div v-if="filtered != null">
  
    <b-sidebar id="sidebar-1" title="Filters" width="90%" shadow>
      <b-col class="col-12">
        <div class="filterMenuMobile" :class="{ filterMenuMin: filterKapali }">
          <div class="p-3" v-if="!filterKapali">
            <b-form-group>
              <b-form-input placeholder="Kelime ile Ara"></b-form-input>
            </b-form-group>
            <b-form-group>
              <multiselect
                v-model="secilenSanatci"
                :multiple="true"
                :taggable="true"
                placeholder="Sanatçı Seç"
                label="ad"
                track-by="ad"
                :options="$store.state.tumSanatciListesi"
                :custom-label="customLabel"
                :show-labels="false"
              >
              </multiselect>
            </b-form-group>

            <b-form-group label="Fiyat">
              <vue-slider
                :max="araMaxFiyat"
                v-model="araFiyat"
                :process="true"
              ></vue-slider>
            </b-form-group>

            <b-form-group>
              <b-alert variant="secondary" show class="mt-3"
                >{{ filtered.length }} Eser
              </b-alert>
            </b-form-group>
          </div>
        </div>
      </b-col>
    </b-sidebar>
    <b-container fluid>
      <b-row class="fff">
        <b-col cols="12" class="filterMobileMenu">
          <b-button
            variant="link"
            v-b-toggle.sidebar-1
            class="sbySiyazBeeyazButton rounded p-1 bg-white btn-block"
            >FİLTRELER</b-button
          >
        </b-col>
      </b-row>

      <b-row
        v-if="!filterKapali"
        class="d-flex align-items-center justify-content-center fff filterMobile"
        :class="{ filterMenuMin: filterKapali }"
      >
        <b-col class="col-sm align-middle">
          <b-form-input placeholder="Kelime ile Ara"></b-form-input>
        </b-col>
        <b-col class="col-sm align-middle">
          <multiselect
            v-model="secilenSanatci"
            :multiple="true"
            :taggable="true"
            placeholder="Sanatçı Seç"
            label="ad"
            track-by="ad"
            :options="$store.state.tumSanatciListesi"
            :custom-label="customLabel"
            :show-labels="false"
          >
          </multiselect>
        </b-col>
        <b-col class="col-sm align-middle">
          <vue-slider
            :max="araMaxFiyat"
            v-model="araFiyat"
            :process="true"
            class="align-middle"
          ></vue-slider>
        </b-col>
        <b-col class="col-sm align-middle">
          <b-alert variant="secondary" show class="mt-3"
            >{{ filtered.length }} Eser
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card-group deck>
            <b-card
              class="m-1 mb-2 font1 mb-2"
              style="z-index: 8 !important"
              header-tag="header"
              footer-tag="footer"
              no-body
              v-for="(item, index) in filtered"
              :key="index"
            >
              <template #header>
                <b-row>
                  <b-col cols="12">
                    <span class="font1 w-100 d-block">
                      <a
                        class="santciLink"
                        :href="'/sanatcilar/' + item.sanatciBilgi.hashLink"
                      >
                        {{ item.sanatciBilgi.ad }}</a
                      ></span
                    >
                  </b-col>
                </b-row>
              </template>
              <VueSlickCarousel
                :arrows="false"
                :dots="false"
                :adaptiveHeight="false"
                dotsClass="resimDotlar"
                lazyLoad="ondemand"
              >
                <div
                  v-for="(item2, index2) in item.eserResimler"
                  :key="index2"
                  class="justify-content-center text-center"
                >
                  <b-button @click="eserBuyut(item)" variant="link">
                    <img
                      :src="$store.state.siteImagePath + item2.resim"
                      :alt="item.sanatciBilgi.ad"
                      class="card-img card-img-top h-25"
                    />
                  </b-button>
                </div>
              </VueSlickCarousel>
              <b-col class="h-100 m-0 p-0">
                <b-container fluid>
                  <b-row>
                    <b-col class="col-12 text-right">
                      <b-button
                        v-if="!item.takibimdemi"
                        variant="link"
                        @click="takipListemeEkle(item)"
                        v-b-popover.hover.top="'Takip Listeme Ekle'"
                        ><img
                          src="../../assets/pngegg.png"
                          style="max-width: 20px"
                        /><br /><span class="kf">Takip Et</span></b-button
                      >
                      <b-button
                        variant="link"
                        @click="takipListemdenCikar(item)"
                        v-b-popover.hover.top="'Takip Listemde => Çıkar'"
                        v-if="item.takibimdemi"
                      >
                        <img
                          src="../../assets/ok.png"
                          style="max-width: 20px"
                        /><br /><span class="kf">Listemde </span>
                      </b-button>
                      <b-button variant="link" v-b-popover.hover.top="'Paylaş'" @click="
                $store.dispatch('socialShareOpen', {
                  link:
                    '/eser/' +
                    item.hashLink,
                  data: item.tanim,
                })
              "
                        ><img
                          src="../../assets/pngfind.com-share-icon-png-1722562.png"
                          style="max-width: 20px"
                        /><br /><span class="kf">Paylaş</span></b-button
                      >
                    </b-col>
                  </b-row>
                </b-container>
                <b-container fluid>
                  <b-row class="p-1 border-top">
                    <b-col cols="12">
                      <span class="font1 w-100 d-block text-center p-1">{{
                        item.fiyat | tlformat
                      }}</span></b-col
                    >

                    <b-col cols="12">
                      <span
                        class="font1 text-muted text-center"
                        style="font-size: 13px"
                        v-html="item.kisaAciklama"
                      ></span>
                    </b-col>
                  </b-row>
                </b-container>

                <b-card-text class="m-1">
                  <b-button
                    block
                    :to="{ path: '/eser/' + item.hashLink }"
                    class="eserDetayBut"
                    variant="outline-dark"
                    >ESER DETAY</b-button
                  >
                </b-card-text>
              </b-col>
              <template #footer>
                <!--<b-button block @click="sureBitti" variant="outline-dark">eR</b-button>-->
                <b-button
                  block
                  @click="sepeteEkle(item)"
                  class="teklifBut"
                  variant="outline-dark"
                  >SEPETE AT</b-button
                >
              </template>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
      v-model="eserBuyutModal"
      hide-footer
      size="xl"
      v-if="eserBuyutItem != null"
      :title="eserBuyutItem.sanatciBilgi.ad"
    >
      <VueSlickCarousel
        :arrows="true"
        :dots="false"
        :adaptiveHeight="false"
        dotsClass="resimDotlar"
        lazyLoad="ondemand"
      >
        <div
          v-for="(item2, index2) in eserBuyutItem.eserResimler"
          :key="index2"
          class="justify-content-center text-center"
        >
          <img
            :src="$store.state.siteImagePath + item2.resim"
            class="card-img card-img-top h-25"
            alt="Image"
          />
        </div>
      </VueSlickCarousel>
    </b-modal>
  </div>
</template>

<script>
var CryptoJS = require("crypto-js");
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  data() {
    return {
      filterKapali: false,
      secilenSanatci: null,
      araFiyat: null,
      araMaxFiyat: 100000,

      eserBuyutModal: false,

      eserBuyutItem: null,
      dd: {
        dots: true,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchThreshold: 5,
      },
      icerigi: null,
      slide: 0,
      perList: 4,
    };
  },

  methods: {
    takipListemdenCikar(eser) {
      var th = this;
        if(localStorage.data == undefined){
         th.$store.dispatch("makeTost", {
          title: "UYARI",
          tip:  "error",
          yazi: "Lütfen Üye Girişi Yapınız",
          full: true,
        });
        return false;
       }
      var conf = {
        url: "/api/users/eserSanatciTakipCikar",
        method: "post",
        headers: {
          "Content-Type": "Application/json",
        },
        data: {
          uyelerId:
            localStorage.data == undefined
              ? 0
              : CryptoJS.AES.decrypt(localStorage.data, "SBYADMIN").toString(
                  CryptoJS.enc.Utf8
                ),
          eserlerId: eser.Id,
          sanatciId: 0,
        },
      };

      axios(conf).then((res) => {
        th.icerigi.filter((o) => o.Id == eser.Id)[0].takibimdemi = false;
        th.$store.dispatch("makeTost", {
          title: "UYARI",
          tip: res.data.success ? "success" : "error",
          yazi: res.data.message,
          full: true,
        });
      });
    },
    takipListemeEkle(eser) {
      var th = this;
        if(localStorage.data == undefined){
         th.$store.dispatch("makeTost", {
          title: "UYARI",
          tip:  "error",
          yazi: "Lütfen Üye Girişi Yapınız",
          full: true,
        });
        return false;
       }
      var conf = {
        url: "/api/users/eserSanatciTakipEkle",
        method: "post",
        headers: {
          "Content-Type": "Application/json",
        },
        data: {
          uyelerId:
            localStorage.data == undefined
              ? 0
              : CryptoJS.AES.decrypt(localStorage.data, "SBYADMIN").toString(
                  CryptoJS.enc.Utf8
                ),
          eserlerId: eser.Id,
          sanatciId: 0,
          grupAdi: "g",
          muzayedeId:0
        },
      };

      axios(conf).then((res) => {
        th.icerigi.filter((o) => o.Id == eser.Id)[0].takibimdemi = true;
        th.$store.dispatch("makeTost", {
          title: "UYARI",
          tip: res.data.success ? "success" : "error",
          yazi: res.data.message,
          full: true,
        });
      });
    },
    filterKapat() {
      this.filterKapali = !this.filterKapali;
    },
    customLabel({ ad, resim }) {
      return ad;
    },
    sepeteEkle(item) {
      this.$store.dispatch("sepeteAt", { item: item });
    },
    eserBuyut(item) {
      this.eserBuyutItem = item;

      this.eserBuyutModal = true;
    },
  },
  components: {
    VueSlickCarousel,
  },
  computed: {
    filtered() {
      var th = this;
      var data = this.icerigi;

      if (th.secilenSanatci) {
        if (th.secilenSanatci.length > 0) {
          var yy = [];

          th.secilenSanatci.forEach((element) => {
            var alld = data;

            yy.push(
              alld.filter((o) => {
                return o.sanatciBilgi.hashLink === element.hashLink;
              })
            );
          });

          let ss = [];
          yy.forEach((element) => {
            element.forEach((element2) => {
              ss.push(element2);
            });
          });

          // if (ss.length > 0) {
          data = ss;
          //}
        }
      }

      if(data!=null){
         data = data.filter((post) => {
        return post.fiyat >= th.araFiyat[0] && post.fiyat <= th.araFiyat[1];
      });
      }
     

      return data;
    },
  },
  props: {
    itemi: {},
    icerikId: Number,
  },
  mounted() {
    var th = this;

    var sendUyeid =
      localStorage.data == undefined
        ? 0
        : CryptoJS.AES.decrypt(localStorage.data, "SBYADMIN").toString(CryptoJS.enc.Utf8);

    var config = {
      url: "/api/tip4Icerik",
      headers: {
        "Content-Type": "Application/json",
      },
      method: "post",
      data: {
        iceriklerId: th.icerikId,
        uyeId: sendUyeid,
      },
    };
    axios(config).then((res) => {
     // console.log("tip 4",res.data)
      th.icerigi = res.data.data;

      var enbuyukFiyat = th.icerigi.reduce((a, b) =>
        Number(a.fiyat) > Number(b.fiyat) ? a : b
      );
      th.araMaxFiyat = enbuyukFiyat.fiyat;
      th.araFiyat = [0, enbuyukFiyat.fiyat];
    });
  },
  created() {
    this.$store.dispatch("tumSanatcilar");
  },
};
</script>

<style>
.card-deck {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)) !important;
  grid-gap: 0.1rem !important;
}
.santciLink:hover {
  color: red !important;
}

.gitIcon {
  display: none;
  position: absolute;
  padding: 4px;
  background-color: rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid darkgray;
  padding: 5px;
  vertical-align: middle;
  text-align: right;
}
.gitIcon a {
  border-radius: 50%;
  border: 1px solid darkgray;
  padding: 4px;
  opacity: 1;
  background-color: white;
}
.hovv:hover .gitIcon {
  display: block;
}
.filterMenuMin {
  width: 60px !important;
}
.filterMenu {
  width: 350px;
  border-right: 1px solid #e3e3e3;
  height: 100vh;
}
.filterMobileMenu {
  display: none;

  z-index: 90;
  margin-left: 0;
  margin-right: 0;

  padding: 8px !important;
}
@media only screen and (max-width: 600px) {
  .filterMobile {
    display: none !important;
  }
  .filterMobileMenu {
    display: block;
  }
}

@import "../../assets/site.css";
.rowClass {
  text-align: left !important;
}
.kf {
  font-size: 10px;
}
.eserDetayBut {
  width: 50%;
  margin: 0 auto;
}
.eserDetayBut:hover {
  color: white !important;
}
.kapandiGizle {
  display: none;
}
.teklifBut:hover {
  color: white !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.resimDotlar {
  position: absolute;
  bottom: 20px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.resimDotlar li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  border-radius: 10px !important;
  border: 1px solid white;
}
.slick-active {
  background-color: white !important;
}
.resimDotlar li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-prev {
  left: 10px !important;
}
.slick-next {
  right: 10px !important;
}
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 999 !important;
}
.fff {
  top: 70px;
  position: sticky;
  z-index: 10;
  background-color: white;
}
</style>
