<template>
  <div id="app">

    <div class="sbyloader" v-if="$store.state.sbyloader">
      <div class="sbyloaderFirst align-items-center">
        <!--  <div class="loader"></div> -->
        <img
          :src="$store.state.siteSetting != null ? $store.state.siteImagePath + $store.state.siteSetting.logo : ''" />

      </div>
    </div>
    <b-modal v-model="$store.state.socialModal" centered size="lg" hide-footer title="Paylaş">
      <social-share></social-share>
    </b-modal>
    <b-modal id="ysifreOlustur" v-model="$store.state.sifreYenileLinkModal" hide-footer title="Yeni Şifreni Belirle"
      no-close-on-backdrop no-close-on-esc>
      <b-container>
        <b-row v-if="yeniSifreOlusturHashKontrol == true && yeniSifreHashDogru == true">
          <b-col cols="12" class="text-center">
            <b-spinner type="grow" label="Bekleyiniz..."></b-spinner>
          </b-col>
        </b-row>
        <b-row v-if="yeniSifreHashDogru == false">
          <b-col cols="12" class="text-center">
            <b-alert variant="warning" show>Geçersiz yada Süresi Dolmuş Link.</b-alert>
          </b-col>
        </b-row>
      </b-container>
      <b-container fluid v-if="yeniSifreHashDogru == true">
        <b-row v-if="yeniSifreOlusturHashKontrol == false">
          <b-col>
            <b-form>
              <b-form-input v-model="$v.yeniSifreOlustur.$model" placeholder="Şifrenizi belirleyiniz"
                :class="{ invalidData: $v.yeniSifreOlustur.$error }" :state="validateState('yeniSifreOlustur')"
                aria-describedby="yeniSifreOlustur-feedback"></b-form-input>
              <b-form-invalid-feedback id="yeniSifreOlustur-feedback">
                Geçersiz Şifre</b-form-invalid-feedback>
            </b-form>
          </b-col>
          <b-col cols="12 mt-4">
            <b-button class="sbySiyazBeeyazButton" block :disabled="$v.$invalid" @click="yeniSifreOlusturKaydet">
              <b-spinner v-if="yeniSifreOlusturState" small type="grow"></b-spinner>
              KAYDET
            </b-button>
          </b-col>
        </b-row>
        <b-row v-if="yeniSifreOlusturIslemYapilamadi == true">
          <b-col cols="12" class="text-center">
            <b-alert variant="warning" show>İşlem Yapılamadı.Lütfen daha sonra deneyiniz.</b-alert>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <b-modal v-model="$store.state.sifreLinkGonderildi" hide-footer title="Şifremi Unuttum">
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <b-alert show variant="secondary">
              <h5>Şifre Sıfırlama Bağlantısı Gönderildi</h5>
              <h6>Lütfen E-Postanı kontrol Et</h6>
            </b-alert>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <b-modal v-model="$store.state.sistemdenCikModal" hide-footer title="Sistemden Çık">
      <b-container>
        <b-row>
          <b-col cols="12" class="p-3 text-center">Sistemden Çıkılacaktır.<br />Onaylıyor musunuz ?</b-col>
          <b-col cols="12" class="p-3">
            <b-button variant="secondary" @click="$store.dispatch('sistemdenCikOnay')" block>ONAYLIYORUM</b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <b-toast v-model="$store.state.tostAc" :variant="$store.state.tostTip" :toaster="$store.state.tostFull"
      header-class="font1" body-class="font1">
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <b-img blank blank-color="#ff5555" class="mr-2" width="12" height="12"></b-img>
          <strong class="mr-auto" v-html="$store.state.tostTitle"></strong>
        </div>
      </template>
      <span v-html="$store.state.tostYazi"></span>
    </b-toast>
    <b-container fluid
      v-if="$store.state.siteSetting != null ? $store.state.uyeid == undefined && $store.state.siteSetting.uyelikAktif == true : false"
      class="d-block d-xl-none d-lg-none p-3 text-center align-items-center justify-content-center">
      <b-row>
        <b-col cols="6" class="text-center">
          <b-button variant="link" @click="$store.dispatch('loginModalAc')" class="mobileGirisYap">Giriş Yap</b-button>
        </b-col>
        <b-col cols="6" class="text-center">
          <b-button variant="link" @click="$store.dispatch('yeniUyeOl')" class="mobileYeniUye">Üye Ol</b-button>
        </b-col>
      </b-row>
    </b-container>
    <top-menu></top-menu>
    <span v-if="$route.params.filter != undefined || $route.fullPath == '/'">
      <div v-for="(item, index) in icerikListesi" :key="index">

        <icerik :icerikId="item.iceriklerId"></icerik>
      </div>
    </span>
    <span v-else>
      <router-view :key="$route.path" />
    </span>


    <tum-sayfalar-icerik-listesi></tum-sayfalar-icerik-listesi>
    <b-modal v-model="$store.state.loginModal" :title="$store.state.loginTitle" hide-footer no-close-on-backdrop
      no-close-on-esc :ok-disabled="true">
      <login-vue v-if="$store.state.loginModalLogin"></login-vue>
      <login-new-vue v-if="$store.state.loginModalNew"></login-new-vue>
      <login-lost-pass v-if="$store.state.loginModalLostPass"></login-lost-pass>
      <login-s-m-s-kod-onay v-if="$store.state.loginModalSmsOnay"></login-s-m-s-kod-onay>
    </b-modal>
  </div>
</template>

<script>
const loginVue = (resolve) => {
  require.ensure(["./components/templates/login.vue"], () => {
    resolve(require("./components/templates/login.vue"));
  });
};
const loginNewVue = (resolve) => {
  require.ensure(["./components/templates/loginNew.vue"], () => {
    resolve(require("./components/templates/loginNew.vue"));
  });
};
const loginLostPass = (resolve) => {
  require.ensure(["./components/templates/loginLostPass.vue"], () => {
    resolve(require("./components/templates/loginLostPass"));
  });
};
const loginSMSKodOnay = (resolve) => {
  require.ensure(["./components/templates/loginSMSKodOnay.vue"], () => {
    resolve(require("./components/templates/loginSMSKodOnay"));
  });
};
import topMenu from "./components/topMenu.vue";

import icerik from "./components/icerik.vue";

import tumSayfalarIcerikListesi from "./components/tumSayfalarIcerikLoader";
import {
  required,
  email,
  minLength,
  sameAs,
  withParams,
  helpers,
} from "vuelidate/lib/validators";
import axios from "axios";
var CryptoJS = require("crypto-js");
import socialShare from "./components/templates/socialShare";
export default {
  metaInfo() {
    return {
      link: [
        { rel: 'canonical', href: (this.$store.state.siteSetting != null ? this.$store.state.siteSetting.siteAdres + '/' + (this.$route.params.filter == undefined ? '' : this.$route.params.filter) : '') }
      ],
      title: this.aktifPage.seoTitle,
      meta: [
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'description', content: this.aktifPage.seoDescriptions }

      ],
      htmlAttrs: {
        lang: 'tr',
        amp: true,

      }
    }
  },
  name: "App",
  data() {
    return {

      yeniSifreOlusturState: false,
      yeniSifreOlustur: "",
      yeniSifreOlusturHashKontrol: true,
      yeniSifreHashDogru: undefined,
      yeniSifreOlusturIslemYapilamadi: false,
      icerikListesi: [],
      aktifPage: {
        Id: 1
      },
    };
  },
  components: {
    icerik,
    topMenu,

    tumSayfalarIcerikListesi,
    loginVue,
    loginNewVue,
    loginLostPass,
    loginSMSKodOnay,
    socialShare,
  },
  validations() {
    return {
      yeniSifreOlustur: {
        required,
        minLength: minLength(6),
      },
    };
  },
  methods: {
    yeniSifreOlusturHashKontrolEt() {
      var th = this;

      var config = {
        url: "/api/users/yeniSifreHashKontrol",
        method: "post",
        headers: {
          "Content-Type": "Application/json",
        },
        data: {
          hash: th.$store.state.sifreYenileLinkModalHash,
        },
      };

      axios(config).then((res) => {
        if (res.data.success) {
          th.yeniSifreOlusturHashKontrol = false;
          th.yeniSifreHashDogru = true;
        } else {
          th.yeniSifreHashDogru = false;
        }
      });
    },
    yeniSifreOlusturKaydet() {
      var th = this;
      event.preventDefault();

      th.yeniSifreOlusturState = true;
      var config = {
        url: "/api/users/sifreOlusturuldu",
        method: "post",
        headers: {
          "Content-Type": "Application/json",
        },
        data: {
          sifre: th.yeniSifreOlustur,
          hash: th.$store.state.sifreYenileLinkModalHash,
        },
      };

      axios(config).then((res) => {
        // console.log("ŞŞŞŞ", res);
        if (res.data.success) {
          th.$bvToast.toast("Hoşgeldiniz", {
            title: "UYARI",
            solid: true,
            variant: "success",
          });
          localStorage.ad = res.data.data.ad;
          localStorage.data = CryptoJS.AES.encrypt(res.data.data.uyeid, "SBYADMIN");

          localStorage.data1 = CryptoJS.AES.encrypt(res.data.data.eposta, "SBYADMIN");

          localStorage.data2 = CryptoJS.AES.encrypt(res.data.data.sifre, "SBYADMIN");
          th.$store.state.uyeAd = res.data.data.ad;
          th.$store.state.sifreYenileLinkModal = false;
          setTimeout(() => {
            th.$router.go("/");
          }, 1500);
        } else {
          th.yeniSifreOlusturIslemYapilamadi = true;
        }
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    firstAllCheck() {
      var th = this;
      th.icerikListesi = [];



      th.$store.dispatch("sepetAl");
    th.$store.dispatch("uyeState");
      th.$store.dispatch("ililceler");
      th.$store.dispatch("uyeBilgileriAl");
      if (th.$store.state.siteMenus.length > 0) {

        var sifreYenileLinkHash = th.$route.params.guid;
        

        if (sifreYenileLinkHash != undefined) {
          th.$store.state.sifreYenileLinkModal = true;
          th.$store.state.sifreYenileLinkModalHash = sifreYenileLinkHash;
        } else {



          if (th.$route.params.filter != undefined || th.$route.fullPath == '/') {

            if (th.$route.params.filter != undefined) {
              th.aktifPage = th.$store.state.siteMenus.filter(
                (o) => o.seoUrl == th.$route.params.filter
              )[0];
            } else {
              th.aktifPage = th.$store.state.siteMenus.filter(
                (o) => o.anaSayfami == true
              )[0];

            }

            th.$store.dispatch("sayfaIcerikListesi", { Id: th.aktifPage.Id }).then((res) => {
              //console.log("icerikler",res)
              th.icerikListesi = res;
            

            });
          } else {


            //th.$route.go({ path: '/muzayede/' + th.$route.params.muzayede })
            // th.aktifPage = th.$store.state.siteMenus.filter(
            //   (o) => o.anaSayfami == true
            // )[0];


          }
          th.$store.state.sbyloader = false;


        }
      }



      th.$root.$on("bv::modal::show", (bvEvent, modalId) => {
        if (modalId == "ysifreOlustur") {
          th.yeniSifreOlusturHashKontrolEt();
        }
        // console.log('Modal is about to be shown', bvEvent, modalId)
      });
    },
  },
  watch: {
    $route() {

      this.firstAllCheck();
    },
  },
  created() {
    //console.log("created")
    var th = this;
    th.$store.state.sbyloader = true;
   

    th.$store.dispatch("tokenAl").then(() => {
      th.$store.dispatch("hashAl").then(() => {
        th.$store.dispatch("siteSettingAl").then(() => {
          th.$store.dispatch("topMenu").then(() => {
            //console.log("SITE SET",th.$store.state.siteSetting)


            if (th.$store.state.siteSetting.googleAnaliticsKod != null) {
              if (document.getElementById("googleAnalitics")) {

                let Script = document.createElement("script");
                Script.setAttribute("async", true);
                Scripts.setAttribute("id", "googleAnalitics")

                Script.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=" + th.$store.state.siteSetting.googleAnaliticsKod);
                document.head.appendChild(Script);

                let scr = document.createElement("script");
                scr.append("window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '" + th.$store.state.siteSetting.googleAnaliticsKod + "');");
                document.head.appendChild(scr);
              }


            }

            th.firstAllCheck();
          });

        });
      });
    })





  },

};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Roboto:wght@300&display=swap");

html,
a,
button,
input {
  font-family: "Montserrat", sans-serif !important;
  text-decoration: none !important;
  color: black !important;
}

* {
  box-sizing: border-box;
}

.share-buttons {
  position: absolute;

  top: 4px;
  left: 5px;
}

.share-buttons .share-button {
  float: left;
  width: 100%;
  margin-top: 4px;
}

.share-buttons .share-button:first-child {
  margin-top: 0;
}

.share-buttons .share-button:after {
  clear: both;
  display: table;
}

.share-button {
  display: block;
  position: relative;
  height: 30px;
}

.share-button:hover {
  cursor: pointer;
}

.share-button:hover .share-button-primary {
  box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.1);
}

.share-button:hover .share-button-secondary-content {
  transform: translate3d(0, 0, 0);
}

.share-button-primary {
  position: absolute;
  background: #fff;
  width: 35px;
  height: 35px;
  border-radius: 18px;
  left: 0;
  top: 50%;
  margin-top: -15px;
  border: 1px solid darkgray;
  /* padding: 10px; */
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  padding: 1px;
}

.share-button-icon {
  display: block;
  color: #242424;
  position: absolute;
  width: 30px;
  line-height: 30px;
  font-size: 16px;
  margin-top: 1px;
}

.share-button-secondary {
  overflow: hidden;
  margin-left: 15px;
  height: 30px;
}

.teklifBut:hover {
  color: white !important;
}

.share-button-secondary-content {
  font-family: sans-serif;
  font-size: 0.75em;
  background: #fff;
  display: block;
  height: 30px;
  text-align: left;
  padding-left: 24px;
  padding-right: 18px;
  line-height: 30px;
  color: #242424;
  border-radius: 0 15px 15px 0;
  transform: translate3d(-100%, 0, 0);
  transition: transform 175ms ease;
}

.invalidData {
  border: 1px solid red !important;
}

.sbyloader {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: white;
  text-align: center;
  z-index: 99999;
}

.sbyloader .sbyloaderFirst {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 50%;
  text-align: center;
  animation: fadein 2s;
  -moz-animation: fadein 2s;
  -webkit-animation: fadein 2s;
  -o-animation: fadein 2s;
}

.sbyloader .loader {
  border: 8px solid #d23a49;
  margin: 0 auto;
  border-radius: 50%;
  border-top: 8px solid #ac3641;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.sbyloader .sbyloaderFirst img {
  max-width: 70%;
  margin-top: 20px;
}

.buton_eser_sepeteAt {
  font-family: "Montserrat", sans-serif;
  color: #ee2e9b !important;
  text-decoration: none !important;
  position: relative !important;
  padding-bottom: 4px !important;
  font-size: 12px !important;
  font-weight: bold !important;
}

.font1 {
  font-family: "Montserrat", sans-serif;
}

html,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif !important;
}

.VueCarousel-pagination {
  margin-top: -40px !important;
}

h5 {
  color: black !important;
}

.mmm {
  position: absolute;
  padding: 5px;
  width: 100%;
  top: 0;
  background-color: #e3e3e3;
  background: rgba(255, 255, 255, 0.8);
  color: black;
}

h1 {
  color: black !important;
}

h2 {
  color: black !important;
}

h3 {
  color: black !important;
}

h4 {
  color: black !important;
}

h5 {
  color: black !important;
}

h6 {
  color: black !important;
}

.mobileGirisYap {
  background-color: white !important;
  color: black !important;
  border-radius: 10px !important;
  width: 100% !important;
  border: 1px solid black !important;
}

.mobileYeniUye {
  background-color: black !important;
  color: white !important;
  border-radius: 10px !important;
  width: 100% !important;
  border: 1px solid white !important;
}

.mobileGizle {
  display: block;
}

.mobileAc {
  display: none;
}

@media (max-width: 576px) {
  .mmm {
    padding: 0 !important;
    position: relative !important;
  }

  .mobileGizle {
    display: none;
  }

  .mobileAc {
    display: block;
  }
}
</style>
