<template>

  <div class="mt-3 mb-3" :class="itemi.ozelClass" :style="itemi.ozelCss">
   
    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1" v-for="(item,index) in icerigi" :key="index">
        <b-card-header header-tag="header" class="p-1 justify-content-left" role="tab">
          <b-button class="text-left " block v-b-toggle="'acc_' + index"  variant="link">{{item.baslik}}</b-button>
        </b-card-header>
        <b-collapse :id="'acc_' + index"   accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <span v-html="item.yazisi"></span>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icerigi: [],
    };
  },

  props: {
    itemi: {},
    icerikId: Number,
  },
  mounted() {
    var th = this;

    var config = {
      url: "/api/tip7icerik",
      headers: {
        "Content-Type": "Application/json",
      },
      method: "post",
      data: {
        Id: th.icerikId,
      },
    };
    axios(config).then((res) => {
     /*  console.log("Tip 7itemi", th.itemi);
      console.log("Tip 7", res.data); */
      th.icerigi = res.data.data;
    });
  },
};
</script>

<style></style>
