<template>
  <div class="col-sm-6 col-lg-3 p-0  mb-2" :class="{ 'kapandiGizle':   kapananlariGoster==false  && lotBilgi.kapandi ==true }">
    <b-card class=" m-1 font1 h-100" style="z-index: 8 !important" header-tag="header" footer-tag="footer" no-body
      :key="sira" >
      <b-modal v-model="takipEkleModal" hide-footer title="Takip Listeme Ekle">
        <b-container>
          <b-row>
            <b-col cols="12">
              <p v-html="takipEkleBilgi"></p>
            </b-col>
            <b-col cols="12"> Onaylıyor musunuz ? </b-col>
            <b-col cols="12">
              <b-button v-if="!takipdenCikar" variant="link" class="btn eserDetayBut btn-outline-dark mt-2"
                @click="takipEkleOnayla" block>
                <b-spinner v-if="takipkleOnayladim" small type="grow"></b-spinner>ONAYLIYORUM
              </b-button>
              <b-button v-if="takipdenCikar" variant="link" class="btn eserDetayBut btn-outline-dark mt-2"
                @click="takipCikarOnayla" block>
                <b-spinner v-if="takipkleOnayladim" small type="grow"></b-spinner>ONAYLIYORUM
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-modal>
      <b-overlay :show="peyGonderAnim" no-wrap>
        <template #overlay>
          <div class="d-flex align-items-center justify-content-center text-center">
            <b-spinner small type="grow" variant="secondary"></b-spinner>
            <b-spinner type="grow" variant="dark"></b-spinner>
            <b-spinner small type="grow" variant="secondary"></b-spinner>
            <!-- We add an SR only text for screen readers -->
          </div>
          <div class="w-100 text-center">Teklifiniz Gönderiliyor...</div>
        </template>
      </b-overlay>
      <b-overlay :show="lotBilgi.kapandi" no-wrap>
        <template #overlay>
          <div class="w-100 text-center m-3">Lot Kapandı</div>
          <div class="w-100 text-center m-3">Sonuçlar</div>
          <div class="w-100 text-center m-3">Esere Git</div>
        </template>
      </b-overlay>
      <template #header>
        <b-row>
          <b-col cols="4">
            <span class="h5 mb-0">{{ lotBilgi.lotNo }}
             
              <!-- {{ lotBilgi.kapandi }} {{parseInt((new Date(lotBitisi).getTime() - new Date().getTime()) / 1000)}} -->
            </span>
          </b-col>
          <b-col cols="8" style="text-align: right !important; font-size: 14px !important">
            <vue-countdown-timer ref="sayy" :id="'tepeCount_' + lotBilgi.lotId" :start-time="lotBaslasi"
              :end-time="lotBitisi" :day-txt="'Gün'" :hour-txt="'Saat'" :minutes-txt="'Dak.'" :seconds-txt="'San.'"
              :show-zero="false" :end-text="''" :key="lotGuncelle">
            </vue-countdown-timer>
          </b-col>
        </b-row>
      </template>

      <VueSlickCarousel :arrows="true" :dots="false" :adaptiveHeight="false" dotsClass="resimDotlar"
        lazyLoad="ondemand">
        <div v-for="(res, index) in lotBilgi.eserResimler" :key="index" class="justify-content-center text-center">
          <b-button @click="lotModalAc()" variant="link">
            <img :src="$store.state.siteImagePath + res.resim" class="card-img card-img-top h-25" alt="Image" />
          </b-button>
        </div>
      </VueSlickCarousel>

      <b-col class="h-100 m-0 p-0">
        <b-container fluid>
          <b-row>
            <b-col class="col-7 col-xl-7 col-lg-7">
              <h4 style="font-weight: bold">{{ lotBilgi.sanatciBilgi.ad }}</h4>
              <p>
                {{ lotBilgi.eserBilgi.kisaAciklama }}
              </p>
            </b-col>
            <b-col class="col-5 col-xl-5 col-lg-5 text-right">
              <b-button v-if="!lotBilgi.takipdemi" variant="link" @click="takipListemeEkle(lotBilgi.eserBilgi)"
                v-b-popover.hover.top="'Takip Listeme Ekle'" class="p-0"><img src="../../assets/pngegg.png"
                  style="max-width: 20px;padding: 0;" /><br /><span class="kf">Takip Et</span></b-button>
              <b-button variant="link" @click="takipListemdenCikar(lotBilgi.eserBilgi)"
                v-b-popover.hover.top="'Takip Listemde => Çıkar'" v-if="lotBilgi.takipdemi" class="p-0">
                <img src="../../assets/ok.png" style="max-width: 20px" /><br /><span class="kf">Listemde
                </span>
              </b-button>

              <b-button variant="link" v-b-popover.hover.top="'Paylaş'" @click="
                $store.dispatch('socialShareOpen', {
                  link:
                    '/eser/' +
                    lotBilgi.eserBilgi.hashLink
                  ,
                  data: lotBilgi.eserBilgi.kisaAciklama,
                })
              "><img src="../../assets/pngfind.com-share-icon-png-1722562.png" style="max-width: 20px" /><br /><span
                  class="kf">Paylaş</span></b-button>
            </b-col>
          </b-row>
        </b-container>
        <b-container fluid>
          <b-row class="p-1 border-top">
            <b-col>Açılış Fiyatı :
              {{ $store.getters.paraFormati(lotIslembilgi[0].Açılıs_Fiyatı) }} ₺</b-col>
          </b-row>
          <b-row class="p-1 border-top">
            <b-col>Toplam Teklif : {{ lotIslembilgi[0].Toplam_Teklif }}</b-col>
          </b-row>
          <b-row class="p-1 border-top">
            <b-col>Komisyon ve KDV Dahil :
              {{ $store.getters.paraFormati(eserNihayiSatis) }} ₺</b-col>
          </b-row>
        </b-container>

        <b-card-text class="m-1">
          <b-button block :href="
            '/muzayede/eserdetay/' + lotBilgi.lotId.toString() + '/' + lotBilgi.lotNo
          " class="eserDetayBut" variant="outline-dark">ESER DETAY</b-button>
        </b-card-text>

        <b-card-text class="text-center">
          <span class="text-muted">Şu anki Fiyat</span>

          <h4 :class="[yakSondurAnim ? 'blink' : '']">
            {{ $store.getters.paraFormati(sunakiFiyat) }} ₺
          </h4>
          <!--<span v-show="yakSondurAnim" class="bg-light p-2 rounded text-muted w-100"
          >Teklif Geldi</span
        >-->

          <p>
            <b-input-group size="sm" class="p-3">
              <b-input-group-prepend>
                <b-button size="sm" text="Button" v-if="peyverFiyat > minVerilmesiGerekenPey" variant="secondary"
                  class="teklifBut" @click="fiyatArttirAzalt('n')"><i class="bi bi-dash-lg"></i></b-button>
              </b-input-group-prepend>
              <para-txt disabled v-model="peyverFiyat" class="form-control text-center" :options="{
                caretPositionOnFocus: 'end',
                currencySymbol: ' ₺',
                currencySymbolPlacement: 's',
                decimalCharacter: ',',
                decimalCharacterAlternative: ',',
                decimalPlaces: 0,
                digitGroupSeparator: '.',
              }"></para-txt>
              <b-input-group-append>
                <b-button class="teklifBut" size="sm" text="Button" @click="fiyatArttirAzalt('p')" variant="secondary">
                  <b-icon icon="plus"></b-icon>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </p>

          <b-alert :show="lotZatenKapali" variant="warning" :key="sira">
            LOT KAPANDI.TEKLİFİNİZ KABUL EDİLMEDİ
          </b-alert>
          <b-alert :show="gecersizTutarVerildi" fade variant="warning">
            Min. Pey Tutar {{ minVerilmesiGerekenPey }} ₺ dir.
          </b-alert>
          <b-alert :show="lotSizde" fade variant="success">
            Tebrikler En Yüksek Teklif Size Ait.
          </b-alert>
          <b-alert :show="lotBaskasinda" fade variant="danger">
            En Yüksek Teklifi Geçemediniz.
          </b-alert>
          <b-alert :show="rezervinVar" fade variant="danger">
            Daha Yüksek Rezerv Teklifiniz Bulunmaktadır.
          </b-alert>
          <b-alert :show="zatenSizde" fade variant="warning">
            Eser Şu anda Sizde.Peyiniz Kabul edilmedi.
          </b-alert>
        </b-card-text>
        <b-card no-body class="m-1 font1" v-if="gelenSanatciBilgi != undefined">
          <b-card-body>
            <h4>{{ gelenSanatciBilgi.ad }}</h4>
            <p>
              <span v-html="gelenSanatciBilgi.aciklama"></span>
            </p>
          </b-card-body>
        </b-card>
      </b-col>
      <template #footer>




        <vue-countdown-timer :id="'altCount_' + lotBilgi.lotId.toString() + '_' + lotBilgi.lotNo"
          :start-time="lotBaslasi" :end-time="lotBitisi" :day-txt="'Gün'" :hour-txt="'Saat'" :minutes-txt="'Dakika'"
          :seconds-txt="'Saniye'" :show-zero="false" :end-text="''" :key="lotGuncelle" @end_callback="sonKontrol()">
          <template slot="countdown" slot-scope="scope">
            <span
              v-if="(parseInt(scope.props.days + scope.props.hours + scope.props.minutes + scope.props.seconds) < 11)">
              {{ scope.props.seconds }}
            </span>
            <span
              v-if="(parseInt(scope.props.days + scope.props.hours + scope.props.minutes + scope.props.seconds) < 11)">


              <b-progress
                :value="10 - parseInt(scope.props.days + scope.props.hours + scope.props.minutes + scope.props.seconds)"
                :max="10" variant="warning" striped :animated="true" class="mt-1 mb-1">
              </b-progress>
            </span>
          </template>
        </vue-countdown-timer>


        <b-button block @click="peyVer" :disabled="peyverBitti" class="teklifBut" variant="outline-dark">TEKLİF VER
        </b-button>
        <small class="text-muted w-100 justify-content-center">
          {{ lotBilgi.lotNo }}
          <vue-countdown-timer :id="'altCount_' + lotBilgi.lotId.toString() + '_' + lotBilgi.lotNo"
            :start-time="lotBaslasi" :end-time="lotBitisi" :day-txt="'Gün'" :hour-txt="'Saat'" :minutes-txt="'Dakika'"
            :seconds-txt="'Saniye'" :show-zero="false" :end-text="''" :key="lotGuncelle">

          </vue-countdown-timer>

        </small>
      </template>
    </b-card>
  </div>
</template>

<script>
import moment from "moment";
require("vue-moment");
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import paraTxt from "./paraTxt";
/* import { setInterval } from "timers";
import { constants } from "os"; */
import axios from "axios";

var CryptoJS = require("crypto-js");
export default {
  components: {
    VueSlickCarousel,
    paraTxt,

  },
  data() {
    return {


      takipdenCikar: false,
      takipEkleModal: false,
      takipEkleBilgi: "",
      takipEkleEser: null,
      takipkleOnayladim: false,
      peyverBitti: false,
      lotZatenKapali: false,
      zatenSizde: false,
      lotGuncelle: this.sira,
      peyGonderAnim: false,
      yakSondurAnim: false,
      gecersizTutarVerildi: false,
      lotSizde: false,
      lotBaskasinda: false,
      rezervinVar: false,
      lotBilgi: null,
      polling: null,

      muzayedeKomisyonOrani: 0,
      eserNihayiSatis: 0,
      minVerilmesiGerekenPey: 0,
      peyverFiyat: 0,
      sunakiFiyat: 0,
      resimlerAyar: {
        dots: true,
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
      lotIslembilgi: [
        {
          Açılıs_Fiyatı: 0,

          Toplam_Teklif: 0,

          Komisyon_ve_Kdv_Dahil: "0 KDV",
        },
      ],
    };
  },
  methods: {

    takipCikarOnayla() {
      var th = this;
      if (localStorage.data == undefined) {
        th.$store.dispatch("makeTost", {
          title: "UYARI",
          tip: "error",
          yazi: "Lütfen Üye Girişi Yapınız",
          full: true,
        });
        return false;
      }
      var conf = {
        url: "/api/users/eserSanatciTakipCikar",
        method: "post",
        headers: {
          "Content-Type": "Application/json",
        },
        data: {
          uyelerId: CryptoJS.AES.decrypt(localStorage.data, "SBYADMIN").toString(
            CryptoJS.enc.Utf8
          ),
          eserlerId: th.takipEkleEser.Id,
          sanatciId: 0,
          muzayedeId: th.lotBilgi.muzayedeId
        },
      };

      axios(conf).then((res) => {
        th.takipEkleBilgi = null;
        th.takipEkleEser = null;
        th.takipEkleModal = false;
        th.$store.dispatch("makeTost", {
          title: "UYARI",
          tip: res.data.success ? "success" : "error",
          yazi: res.data.message,
          full: true,
        });
        th.lotuAnaBilgiAl();
      });
    },
    takipEkleOnayla() {
      var th = this;
      if (localStorage.data == undefined) {
        th.$store.dispatch("makeTost", {
          title: "UYARI",
          tip: "error",
          yazi: "Lütfen Üye Girişi Yapınız",
          full: true,
        });
        return false;
      }
      var conf = {
        url: "/api/users/eserSanatciTakipEkle",
        method: "post",
        headers: {
          "Content-Type": "Application/json",
        },
        data: {
          uyelerId: CryptoJS.AES.decrypt(localStorage.data, "SBYADMIN").toString(
            CryptoJS.enc.Utf8
          ),
          eserlerId: th.takipEkleEser.Id,
          sanatciId: 0,
          grupAdi: "m",
          muzayedeId: th.lotBilgi.muzayedeId
        },
      };

      axios(conf).then((res) => {
        th.takipEkleBilgi = null;
        th.takipEkleEser = null;
        th.takipEkleModal = false;
        th.$store.dispatch("makeTost", {
          title: "UYARI",
          tip: res.data.success ? "success" : "error",
          yazi: res.data.message,
          full: true,
        });
        th.lotuAnaBilgiAl();
      });
    },
    takipListemeEkle(eserBilgi) {
      var th = this;
      /* th.takipEkleBilgi =
        (eserBilgi.kisaAciklama == null ? "" : eserBilgi.kisaAciklama) +
        " " +
        eserBilgi.tanim +
        "<br> Takip Listenize Eklenecektir.";
        th.takipdenCikar=false; */
      th.takipEkleEser = eserBilgi;
      th.takipEkleOnayla();
      /*  th.takipEkleModal = true; */
    },
    takipListemdenCikar(eserBilgi) {
      var th = this;
      /* th.takipEkleBilgi =
        (eserBilgi.kisaAciklama == null ? "" : eserBilgi.kisaAciklama) +
        " " +
        eserBilgi.tanim +
        "<br> Takip Listenizden Çıkarılacaktır.";
      th.takipdenCikar = true; */
      th.takipEkleEser = eserBilgi;
      th.takipCikarOnayla();
      /* th.takipEkleModal = true; */
    },

    sonKontrol() {
      var th = this;
      var config = {
        method: "post",
        url: "/api/lots/lotPeySayisiAl",
        data: {
          Id: th.lotBilgi.lotId,
        },
        headers: {
          "Content-Type": "Application/json",
        },
      };

      axios(config)
        .then(function (response) {
          if (
            parseInt(th.lotBilgi.peyleri.length) < parseInt(response.data.data.peySayisi)
          ) {
            th.peyGonderAnim = false;
            th.lotSizde = false;
            th.lotBaskasinda = false;
            th.lotKapaniyorUyariBaslat = false;
            th.lotBilgi.kapandi = false;

            //son sayımı uzat
            var someDate = new Date();
            var son10Saniye = 10;
            this.lotKapaniyorAlertSure = someDate;
            this.lotKapaniyorAlertBittiSure = someDate.setDate(
              someDate.getDate() + son10Saniye
            );
            th.lotuAnaBilgiAl();
          } else {
            th.lotKapaniyorUyariBaslat = false;
            th.lotBilgi.kapandi = true;
            th.lotKapatGonder();
          }
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    lotKapatGonder() {
      var th = this;

      var lotid = th.lotBilgi.lotId;

      var config = {
        method: "post",
        url: "/api/lots/lotKapat",
        data: {
          Id: lotid,
        },
        headers: {
          "Content-Type": "Application/json",
        },
      };

      axios(config)
        .then(function (response) {
          /*  if(lotid==129){
                console.log("Kapandı Durumu " )
                console.log(response)
            } */
          //th.lotuAnaBilgiAl();
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    sureBitti() {

      if (!this.lotBilgi.kapandi) {
        /*   var someDate = new Date();
                var son10Saniye = 10;
                this.lotKapaniyorAlertSure = someDate;
                this.lotKapaniyorAlertBittiSure = someDate.setSeconds(
                    someDate.getSeconds() + son10Saniye
                );
                this.lotKapaniyorUyariBaslat = true; */
        this.sonKontrol();
      }
    },
    lotModalAc() {
      this.$emit("lotModalAc", this.lotBilgi);
    },
    peyVer() {
      var th = this;
      th.peyverBitti = true;


      th.$store.dispatch("uyeState").then((res) => {
        if (th.$store.state.uyeid == true) {
          if (th.peyverFiyat < th.minVerilmesiGerekenPey) {
            th.gecersizTutarVerildi = true;
            setTimeout(() => {
              th.gecersizTutarVerildi = false;
            }, 3000);
            th.peyverFiyat = th.minVerilmesiGerekenPey;
            setTimeout(() => {
              th.peyverBitti = false;
            }, 3000);
          } else {
            th.peyGonderAnim = true;
            th.lotSizde = false;
            th.lotBaskasinda = false;
            /*  th.lotKapaniyorUyariBaslat =false; */
            th.lotBilgi.kapandi = false;
            var gonderuyeid = CryptoJS.AES.decrypt(
              localStorage.data,
              "SBYADMIN"
            ).toString(CryptoJS.enc.Utf8);
            /*   console.log("UUUUUUUU",CryptoJS.AES.decrypt(localStorage.data, "SBYADMIN").toString(
                    CryptoJS.enc.Utf8
                  ));
                  alert(gonderuyeid) */
            setTimeout(() => {
              var config = {
                method: "post",
                url: "/api/lots/peyver",
                data: {
                  uyeid: gonderuyeid,
                  muzayedeid: th.lotBilgi.muzayedeId,
                  lotid: th.lotBilgi.lotId,
                  verilmesiGerekenMinPey: th.minVerilmesiGerekenPey,
                  suankiFiyat: th.sunakiFiyat,
                  verilenPey: th.peyverFiyat,
                  arttirimTutari: th.arttirimTutari,
                },
                headers: {
                  "Content-Type": "Application/json",
                },
              };
              // console.log("GOND", config);
              axios(config)
                .then(function (response) {
                  //th.lots = response.data.data;

                  th.peyGonderAnim = false;

                  //console.log("Verilen Pey Sonuç : ", response.data);

                  if (response.data.message == "zatenSizde") {
                    th.zatenSizde = true;
                    setTimeout(() => {
                      th.zatenSizde = false;
                    }, 3000);
                  }
                  if (response.data.message == "Sizde") {
                    th.lotSizde = true;
                    setTimeout(() => {
                      th.lotSizde = false;
                    }, 3000);
                  }
                  if (response.data.message == "-") {
                    th.lotBaskasinda = true;
                    setTimeout(() => {
                      th.lotBaskasinda = false;
                    }, 3000);
                  }

                  if (response.data.message == "Rezerv Var") {
                    th.rezervinVar = true;
                    setTimeout(() => {
                      th.rezervinVar = false;
                    }, 3000);
                  }
                  // console.log("gelen",response.data.message)
                  if (response.data.message == "Kapandı") {
                    th.lotZatenKapali = true;
                    setTimeout(() => {
                      th.lotZatenKapali = false;
                    }, 3000);
                  }
                  // console.log("gelen",response.data.message)
                  th.lotOku();



                })
                .catch(function (error) {
                  th.show = false;
                  setTimeout(() => {
                    th.peyverBitti = false;
                  }, 3000);
                });
            }, 10);
          }
        } else {
          setTimeout(() => {
            th.peyverBitti = false;
          }, 3000);
          this.$bvToast.toast("Lütfen Sisteme Giriş Yapınız", {
            title: "UYARI",
            solid: true,
            variant: "danger",
          });
        }
      });
    },
    yakSondur() {
      var th = this;

      th.yakSondurAnim = true;

      setTimeout(() => {
        th.yakSondurAnim = false;
      }, 8000);
    },
    fiyatArttirAzalt(event) {
      var verilenPeySayisi = this.lotBilgi.peyleri.length;
      var minVerilebilecek =
        this.sunakiFiyat + (verilenPeySayisi == 0 ? 0 : this.arttirimTutari);

      if (event == "p") {
        this.peyverFiyat += this.arttirimTutari;
      } else {
        if (this.peyverFiyat - this.arttirimTutari > this.sunakiFiyat) {
          this.peyverFiyat -= this.arttirimTutari;
        } else {
          this.peyverFiyat = minVerilebilecek;
        }
      }
    },
    lotOku() {
      var peySayi = this.lotBilgi.peyleri.length;
      //console.log("arttirimi",this.arttirimTutari,"sn fiyat",this.lotBilgi.sonFiyat)
      this.peyverFiyat =
        peySayi == 0
          ? this.lotBilgi.acilisFiyat
          : this.lotBilgi.sonFiyat + this.arttirimTutari;
      this.sunakiFiyat =
        peySayi == 0 ? this.lotBilgi.acilisFiyat : this.lotBilgi.sonFiyat;
      this.eserNihayiSatis =
        this.sunakiFiyat + (this.sunakiFiyat * this.lotBilgi.muzayedeKomisyonOrani) / 100;

      this.minVerilmesiGerekenPey = this.peyverFiyat;

      this.eserNihayiSatis = this.eserNihayiSatis + (this.eserNihayiSatis * 18) / 100;
      this.muzayedeKomisyonOrani = this.lotBilgi.muzayedeKomisyonOrani;

      this.lotIslembilgi = [
        {
          Açılıs_Fiyatı: this.lotBilgi.acilisFiyat,

          Toplam_Teklif: peySayi,

          Komisyon_ve_Kdv_Dahil:
            this.$store.getters.paraFormati(this.eserNihayiSatis) + " ₺ + KDV",
        },
      ];
      setTimeout(() => {
        this.peyverBitti = false;
      }, 3000);
    },
    lotuAnaBilgiAl() {
      var th = this;

      var lotid = th.lotBilgi.lotId;

      var config = {
        method: "post",
        url: "/api/lots/lotBilgiAl",
        data: {
          lotId: lotid,
          cariId: CryptoJS.AES.decrypt(localStorage.data, "SBYADMIN").toString(
            CryptoJS.enc.Utf8
          ),
        },
        headers: {
          "Content-Type": "Application/json",
        },
      };

      axios(config)
        .then(function (response) {
          // console.log("bilgisi", response);
          th.lotBilgi = response.data.data[0];
          th.lotGuncelle += 1;
          th.lotOku();
          th.yakSondur();
          /*   th.sureGuncelle += 1;
          console.log("Güncellendi " + th.lotBilgi.peyleri.length);
          // if(guncellendi){

          th.lotOku();
          th.yakSondur(); */
          // th.pollData()
          // }
        })
        .catch(function (error) {
          console.log(error);
        });
    },


    pollData() {
      var th = this;


      var bittimi = parseInt((new Date(th.lotBitisi).getTime() - new Date().getTime()) / 1000)
      if (bittimi <= 0) {
        //console.log(th.lotBilgi.lotNo , "kkkk")
        th.lotBilgi.kapandi == true;
        th.sureBitti();
      } else {
        if (!th.lotBilgi.kapandi) {
          th.polling = setInterval(() => {
            var config = {
              method: "post",
              url: "/api/lots/lotPeySayisiAl",
              data: {
                Id: th.lotBilgi.lotId,
              },
              headers: {
                "Content-Type": "Application/json",
              },
            };

            axios(config)
              .then(function (response) {

                var bittimiSure = parseInt(Math.abs(new Date().getTime() - new Date(th.lotBitisi).getTime()) / 1000);
                // console.log("bbbb",bittimiSure)
                /*  if (th.lotBilgi.lotNo == "Lot-1") {
                  console.log(th.lotBilgi.kapandi + "_ " + response.data.data.durum);
                } */
                th.lotBilgi.kapandi = response.data.data.durum;

                if (
                  parseInt(th.lotBilgi.peyleri.length) <
                  parseInt(response.data.data.peySayisi)
                ) {
                  th.lotuAnaBilgiAl();
                }
              })
              .catch(function (error) {
                // console.log(error);
              });
          }, 2000);
        }
      }

    },
  },
  props: {
    lotTumBilgi: Object,
    sira: Number,
    distan: Boolean,
    gelenSanatciBilgi: Object,
    kapananlariGoster: Boolean,
  },
  computed: {

    lotBaslasi() {
      return moment(new Date().getTime());
    },
    lotBitisi() {
      return moment(new Date(this.lotBilgi.lotBitis));
    },
    arttirimTutari: function () {
      var th = this;

      var arttirim = th.lotBilgi.lotArttirimTabloBilgi.filter((k) => {
        return (
          parseInt(k.fiyat1) <= parseInt(th.peyverFiyat) &&
          parseInt(k.fiyat2) >= parseInt(th.peyverFiyat)
        );
      });

      //console.log("Arttırım",th.peyverFiyat ,arttirim[arttirim.length-1]);
      if (arttirim.length == 0) {
        return 0;
      } else {
        return arttirim[arttirim.length - 1].arttirim;
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  created() {
    var th = this;
    th.lotBilgi = th.lotTumBilgi;



    // console.log("OLLLLL", th.lotBilgi.muzayedeId);
    var arttirim = th.lotBilgi.lotArttirimTabloBilgi.filter((k) => {
      return (
        parseInt(th.lotBilgi.sonFiyat) >= parseInt(k.fiyat1 - 1) &&
        parseInt(th.lotBilgi.sonFiyat) <= parseInt(k.fiyat2 - 1)
      );
    });

    if (arttirim.length > 0) {
      th.peyverFiyat = th.lotBilgi.sonFiyat + arttirim[0].arttirim;
    }
    th.lotOku();
    // console.log("KAPANDU", th.lotBilgi.kapandi)
    if (th.lotBilgi.kapandi) {
      var someDate = new Date();
      var son10Saniye = 1000;
      th.lotKapaniyorAlertSure = someDate;
      th.lotKapaniyorAlertBittiSure = someDate.setDate(someDate.getDate() + son10Saniye);
      th.lotKapaniyorUyariBaslat = false;
    } else {
      th.pollData();

    }
  },
};
</script>

<style>
@import "../../assets/site.css";

.rowClass {
  text-align: left !important;
}

.kf {
  font-size: 10px;
}

.eserDetayBut {
  width: 50%;
  margin: 0 auto;
}

.eserDetayBut:hover {
  color: white !important;
}

.kapandiGizle {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.resimDotlar {
  position: absolute;
  bottom: 20px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.resimDotlar li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  border-radius: 10px !important;
  border: 1px solid white;
}

.slick-active {
  background-color: white !important;
}

.resimDotlar li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-prev {
  left: 10px !important;
}

.slick-next {
  right: 10px !important;
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 999 !important;
}
</style>
