<template>
  <b-container class="text-center">
   <b-row>
      <b-col class="mb-4" >
        <s-facebook
        class="p-3"
          :window-features="windowFeatures"
          :share-options="facebookShareOptions"
          :use-native-behavior="useNativeBehavior"
          style="text-decoration: none"
        >
          <img
            :src="require('../../assets//social_logos/fc1.jpg')"
            class="socialIcon"
          />
        </s-facebook>
        <s-whats-app
         class="p-3"
          :window-features="windowFeatures"
          :share-options="whatsappshareOptions"
          :use-native-behavior="useNativeBehavior"
        >
          <img
            :src="require('../../assets/social_logos/fc2.jpg')"
            class="socialIcon"
          />
        </s-whats-app>
         <s-email :share-options="emailshareOptions"  class="p-3">
          <img :src="require('../../assets/social_logos/fc3.jpg')" class="socialIcon" />
        </s-email>
      </b-col>

      
     
    </b-row>
   
  <b-row>
  <b-col cols="8">
   <input type="text" class="serdartxt" id="tooltip-button-1" v-on:focus="$event.target.select()" ref="kopi"  :value="this.$store.state.siteAddress   + this.$store.state.socialData.link" />
  </b-col>
  <b-col cols="4" class="text-left">
  <b-button variant="link"  @click.prevent="adresKopyala" class="sbySiyazBeeyazButton btn-block">Kopyala</b-button>
    
     <b-tooltip :disabled.sync="adresKopyalanmadi" :show.sync="adresKopyalandi" ref="tooltip" target="tooltip-button-1">
       Kopyalandi
      </b-tooltip>
  </b-col>
  </b-row>
   
    
  </b-container>
</template>

<script>
  
  import {SFacebook,  STwitter, SWhatsApp, SEmail } from '../../../node_modules/vue-socials';

export default {
  components: {
    SFacebook,
    STwitter,
    SWhatsApp,
    SEmail,
  },
  data() {
    return {
        adresKopyalanmadi:true,
      adresKopyalandi:false,
      windowFeatures: {},
      facebookShareOptions: {
        url: this.$store.state.siteAddress   + this.$store.state.socialData.link,
        quote:   this.$store.state.siteAddress   ,
        hashtag: "#Sanatmezat"
       
      },
      twitterShareOptions: {
        url: this.$store.state.siteAddress   + this.$store.state.socialData.link,
        text: "",
        hashtags: ["Sanat Mezat"],
        via: "twitterdev",
      },
      emailshareOptions: {
        mail: "",
        cc: "",
        bcc: "",
        subject: "Sizin için Önerildi.\n" + this.$store.state.socialData.data, 
        body: "Sizin için önerilen link .\n" +  this.$store.state.socialData.data + ", \n" + this.$store.state.siteAddress   + this.$store.state.socialData.link + "\n" + " SANAT MEZAT.\n www.sanatmezat.com",
      },
      whatsappshareOptions: {
        number: "90(555)972-81-61",
        text:"Sizin için önerilen link ." + "\n" +  this.$store.state.socialData.data + " \n" + this.$store.state.siteAddress   + this.$store.state.socialData.link + "\n" +"  SANAT MEZAT.\n www.sanatmezat.com",
      },
      useNativeBehavior: false,
    };
  },
  
  methods: {
    adresKopyala(){
        var th = this;
       
       // Url.innerHTML = window.location.href;
        //console.log(Url.innerHTML)
        this.$refs.kopi.focus();
        document.execCommand('copy');
        this.adresKopyalanmadi =false;
        this.adresKopyalandi = true;
        if (!this.adresKopyalanmadi) {
            if(this.adresKopyalandi){
                 this.$refs.tooltip.$emit('enable')
                 setTimeout(() => {
                     this.adresKopyalanmadi =true;
                        this.adresKopyalandi = false;
                 }, 1500);
            }
         
           
        } else {
          this.$refs.tooltip.$emit('disable')
        }
    }
  },
};
</script>

<style>
.socialIcon {
  max-width: 80%;
}
.serdartxt{
    padding: 10px;
    width: 100%;
    border:0;
    border-bottom: 1px solid lightgray;
}
</style>
