<template>
  <div class="container p-2">
 
    <b-card
      no-body
      v-for="(item, index) in blogs"
      :key="index"
      class="overflow-hidden font1 mb-3"
    >
      <b-row no-gutters>
        <div class="col-12 d-flex p-3">
          <div class="col-12 col-xl-8 col-lg-8 left">
            <h4>{{ item.baslik }}</h4>
          </div>
          <div class="col-12 col-xl-4 col-lg-4 text-right">
            <h6>{{ item.kayitTarihi }}</h6>
          </div>
        </div>
        <b-col md="6">
          <b-card-img
            @click="blogOpen(item)"
            style="cursor: pointer"
            :src="$store.state.siteImagePath + item.resmi"
            :alt="item.baslik"
            class="rounded-0"
          ></b-card-img>
        </b-col>
        <b-col md="6">
          <b-card-body>
            <b-card-text>
              {{ item.kisaAciklama }}
            </b-card-text>
            <p>
              <b-button variant="link" :to="'/blog/' + item.hashlink">Devamı...</b-button>
            </p>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import moment from "moment";
require("vue-moment");
export default {
  data() {
    return {
      blogs: undefined,
    };
  },
  props: {
    icerik: Object,
    icerikId: Number,
  },
  methods: {
    blogOpen(item) {
      this.$router.push({ path: "/blog/" + item.hashlink });
    },
  },
  created() {
    var th = this;

    var config = {
      url: "/api/tip10Icerik",
      headers: {
        "Content-Type": "Application/json",
      },
     
      data:{
        Id:th.icerikId
      },
      method: "post",
    };
    console.log("jjjj",th.icerikId)
    axios(config).then((res) => {
      console.log("BBBB", res);
      th.blogs = res.data.data;
    });
  },
};
</script>

<style></style>
